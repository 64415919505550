import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarLogo = _resolveComponent("SidebarLogo")!
  const _component_SidebarItem = _resolveComponent("SidebarItem")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{'has-logo': _ctx.showLogo}, "sideWrap"])
  }, [
    (_ctx.showLogo)
      ? (_openBlock(), _createBlock(_component_SidebarLogo, {
          key: 0,
          collapse: _ctx.isCollapse
        }, null, 8, ["collapse"]))
      : _createCommentVNode("", true),
    _createVNode(_component_el_scrollbar, { "wrap-class": "scrollbar-wrapper" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_menu, {
          collapse: !_ctx.isCollapse,
          "unique-opened": false,
          "default-active": _ctx.activeMenu,
          "background-color": _ctx.variables.menuBg,
          "text-color": _ctx.variables.menuText,
          "active-text-color": _ctx.menuActiveTextColor,
          mode: "vertical"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, (route) => {
              return (_openBlock(), _createBlock(_component_SidebarItem, {
                key: route.path,
                item: route,
                "base-path": route.path,
                "is-collapse": _ctx.isCollapse,
                "is-first-level": true
              }, null, 8, ["item", "base-path", "is-collapse"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["collapse", "default-active", "background-color", "text-color", "active-text-color"])
      ]),
      _: 1
    })
  ], 2))
}