import { ref } from 'vue';

export const isValidUsername = (str: string) => ['admin', 'editor'].indexOf(str.trim()) >= 0;

export const isExternal = (path: string) => /^(https?:|mailto:|tel:)/.test(path);

export const isArray = (arg: any) => {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }
  return Array.isArray(arg);
};

export const isValidURL = (url: string) => {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
};

/**
 * at least 1 letter and 1 number
 * @param {string} password
 */
export const isValidPassword = (password: string) => {
  if (password === '' || password === undefined) {
    return new Error('請輸入您的密碼');
  } else if (password !== '') {
    if (!password.match(/([a-zA-Z])+/)) {
      return new Error('您的密碼必須包含至少一個英文字母');
    }
    if (!password.match(/([0-9])+/)) {
      return new Error('您的密碼必須包含至少一個數字');
    }
    if (!password.match(/^.{8,20}$/)) {
      return new Error('您的密碼要包含 8-20 字元');
    }
    return true;
  }
};

export const isValidCharacterLength = (context: string, textLength = 50) => {
  const rule = textLength === 50 ? /^.{1,50}$/ : /^.{1,130}$/;
  if (!context.match(rule)) {
    if (context.length < 1) {
      return new Error('此為必填欄位');
    }
    return new Error('超出字數限制');
  }
  return true;
};

export const isValidNumberString = (numberString: string) => {
  if (numberString.match(/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/)) {
    return true;
  } else {
    return new Error('請填入數字');
  }
};

// TODO: should move to composables
/**
 * el-form-item表單檢查
 * 顯示PHP laraval request error msg到表單item上
 * 使用方式見 IPWhitelistDetail.vue
 *
 */
export default function() {
  /**
   * 錯誤的訊息
   * {
   *  title:['msg'],
   *  sort:['msg']
   * }
   *  */
  const formErrors = ref({});
  // 綁定:error後，propName檢查是否有該物件
  function bindFormItemError(propName: string) {
    return formErrors.value != null &&
      // eslint-disable-next-line no-prototype-builtins
      formErrors.value.hasOwnProperty(propName) &&
      formErrors.value[propName].length > 0
      ? formErrors.value[propName][0]
      : null;
  };
  return {
    formErrors,
    bindFormItemError
  };
}
