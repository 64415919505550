import { shallowRef } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const dictionaryRouter: Array<RouteRecordRaw> = [
  {
    path: '/dictionary',
    component: shallowRef(Layout),
    redirect: '/dictionary/topics',
    meta: {
      title: 'dictionary',
      icon: '#icon-dictionary'
    },
    children: [
      {
        path: 'topics',
        component: () => import(/* webpackChunkName: "topics-daily" */ '@/views/topics/List.vue'),
        name: 'list_topics',
        meta: {
          title: 'topics',
          icon: '#icon-topic'
        }
      },
      {
        path: 'topics/create',
        component: () => import(/* webpackChunkName: "topics-create" */ '@/views/topics/Create.vue'),
        name: 'create_topic',
        meta: {
          title: 'createTopic',
          activeMenu: '/dictionary/topics',
          hidden: true
        }
      },
      {
        path: 'topics/edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "topics-edit" */ '@/views/topics/Edit.vue'),
        name: 'edit_topic',
        meta: {
          title: 'editTopic',
          activeMenu: '/dictionary/topics',
          hidden: true
        }
      },
      {
        path: 'tags',
        component: () => import(/* webpackChunkName: "tags-daily" */ '@/views/tags/List.vue'),
        name: 'list_tags',
        meta: {
          title: 'tags',
          activeMenu: '/dictionary/tags',
          icon: '#icon-tag'
        }
      },
      {
        path: 'tags/create',
        component: () => import(/* webpackChunkName: "tags-create" */ '@/views/tags/Create.vue'),
        name: 'create_tag',
        meta: {
          title: 'createTag',
          activeMenu: '/dictionary/tags',
          hidden: true
        }
      },
      {
        path: 'tags/edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "tags-edit" */ '@/views/tags/Edit.vue'),
        name: 'edit_tag',
        meta: {
          title: 'editTag',
          activeMenu: '/dictionary/tags',
          hidden: true
        }
      },
      {
        path: 'directors',
        component: () => import(/* webpackChunkName: "directors-daily" */ '@/views/directors/List.vue'),
        name: 'list_directors',
        meta: {
          title: 'directors',
          activeMenu: '/dictionary/directors',
          icon: '#icon-director'
        }
      },
      {
        path: 'directors/create',
        component: () => import(/* webpackChunkName: "directors-daily" */ '@/views/directors/Create.vue'),
        name: 'create_director',
        meta: {
          title: 'createDirector',
          activeMenu: '/dictionary/directors',
          hidden: true
        }
      },
      {
        path: 'directors/edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "directors-daily" */ '@/views/directors/Edit.vue'),
        name: 'edit_director',
        meta: {
          title: 'editDirector',
          activeMenu: '/dictionary/directors',
          hidden: true
        }
      },
      {
        path: 'actors',
        component: () => import(/* webpackChunkName: "actors-daily" */ '@/views/actors/List.vue'),
        name: 'list_actors',
        meta: {
          title: 'actors',
          activeMenu: '/dictionary/actors',
          icon: '#icon-model'
        }
      },
      {
        path: 'actors/create',
        component: () => import(/* webpackChunkName: "actors-daily" */ '@/views/actors/Create.vue'),
        name: 'create_actor',
        meta: {
          title: 'createActor',
          activeMenu: '/dictionary/actors',
          hidden: true
        }
      },
      {
        path: 'actors/edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "actors-daily" */ '@/views/actors/Edit.vue'),
        name: 'edit_actor',
        meta: {
          title: 'editActor',
          activeMenu: '/dictionary/actors',
          hidden: true
        }
      },
      {
        path: 'genres',
        component: () => import(/* webpackChunkName: "genres-daily" */ '@/views/genres/List.vue'),
        name: 'list_genres',
        meta: {
          title: 'genres',
          activeMenu: '/dictionary/genres',
          icon: '#icon-genre'
        }
      },
      {
        path: 'genres/create',
        component: () => import(/* webpackChunkName: "genres-daily" */ '@/views/genres/Create.vue'),
        name: 'create_genre',
        meta: {
          title: 'createGenre',
          activeMenu: '/dictionary/genres',
          hidden: true
        }
      },
      {
        path: 'genres/edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "genres-daily" */ '@/views/genres/Edit.vue'),
        name: 'edit_genre',
        meta: {
          title: 'editGenre',
          activeMenu: '/dictionary/genres',
          hidden: true
        }
      }
    ]
  }
];

export default dictionaryRouter;
