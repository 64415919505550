import { request } from './utils';

import {
  CouponResponse,
  CouponsResponse,
  GetCouponRequestOptions,
  GetCouponsRequestOptions,
  CreateCouponRequestOptions,
  UpdateCouponRequestOptions,
  DeleteCouponRequestOptions,
  SucceededResponse,
  TakeCouponDownRequestOptions
} from './interfaces';

export function getCoupon({ couponId }: GetCouponRequestOptions): Promise<CouponResponse> {
  return request({
    url: `/coupons/${couponId}`,
    method: 'get'
  });
}

export function getCoupons({ query }: GetCouponsRequestOptions): Promise<CouponsResponse> {
  return request({
    url: '/coupons',
    method: 'get',
    params: query
  });
}

export function createCoupon({ data }: CreateCouponRequestOptions): Promise<CouponResponse> {
  return request({
    url: '/coupons',
    method: 'post',
    data
  });
}

export function updateCoupon({ couponId, data }: UpdateCouponRequestOptions): Promise<CouponResponse> {
  return request({
    url: `/coupons/${couponId}`,
    method: 'put',
    data
  });
}

export function deleteCoupon({ couponId }: DeleteCouponRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/coupons/${couponId}`,
    method: 'delete'
  });
}

export function takeCouponDown({ couponId }: TakeCouponDownRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/coupons/${couponId}/taken-down`,
    method: 'post'
  });
}
