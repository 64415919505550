import { request } from './utils';

import {
  GetEventsSuggestionRequestOptions,
  EventsSuggestionResponse,
  SuggestionsCountResponse,
  GetSuggestionsRequestOptions,
  CreateSuggestionRequestOptions,
  ChangeStatusRequestOptions,
  SuggestionsResponse,
  SucceededResponse,
  ExcludeAllRequestOptions
} from './interfaces';

// 活動建議列表
export function getEventsSuggestion({ query }: GetEventsSuggestionRequestOptions): Promise<EventsSuggestionResponse> {
  return request({
    url: '/wte/events/suggestions-count',
    method: 'get',
    params: query
  });
}

// 列表上面卡片
export function getSuggestionsCount(
  { eventId }: { eventId: string }, { query }: GetSuggestionsRequestOptions = {}): Promise<SuggestionsCountResponse> {
  return request({
    url: `/wte/events/${eventId}/suggestions-count`,
    method: 'get',
    params: query
  });
}

// 建議列表
export function getSuggestions(
  { eventId }: { eventId: string }, { query }: GetSuggestionsRequestOptions = {}): Promise<SuggestionsResponse> {
  return request({
    url: `/wte/events/${eventId}/suggestions`,
    method: 'get',
    params: query
  });
}

// 人工新增建議
export function createSuggestion({ eventId, data }: CreateSuggestionRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/wte/events/${eventId}/suggestions`,
    method: 'post',
    data
  });
}

// 刪除後台新增建議
export function deleteSuggestion({ suggestionId }: ChangeStatusRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/wte/suggestions/${suggestionId}`,
    method: 'delete'
  });
}

// 建議更新為淘汰狀態
export function exclude({ suggestionId }: ChangeStatusRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/wte/suggestions/${suggestionId}/exclude`,
    method: 'post'
  });
}

// 建議更新為入選狀態
export function include({ suggestionId }: ChangeStatusRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/wte/suggestions/${suggestionId}/include`,
    method: 'post'
  });
}

// 建議更新為待審核狀態
export function verifying({ suggestionId }: ChangeStatusRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/wte/suggestions/${suggestionId}/verifying`,
    method: 'post'
  });
}

// 建議更新為淘汰狀態(All)
export function excludeAll({ data }: ExcludeAllRequestOptions): Promise<SucceededResponse> {
  return request({
    url: '/wte/suggestions/exclude',
    method: 'post',
    data
  });
}
