import { createFormData, request } from './utils';
import { FORM_DATE_HEADER } from './constants';
import {
  CreateEventRequestOptions,
  DeleteEventRequestOptions,
  EnrollmentsResponse,
  EventResponse,
  EventsResponse,
  ExportEventsStatistics,
  ExportEventsStatisticsResponse,
  ExportResponse,
  GetEventsEnrollmentsRequestOptions,
  GetEventsRequestOptions,
  GetEventsStatisticsRequestOptions,
  StatisticsResponse, SucceededResponse, UpdateEventRequestOptions
} from './interfaces';

export function getEvents({ query }: GetEventsRequestOptions): Promise<EventsResponse> {
  return request({
    url: '/events',
    method: 'get',
    params: query
  });
}

export function getEvent({ id }: { id: string | number }): Promise<EventResponse> {
  return request({
    url: `/events/${id}`,
    method: 'get'
  });
}

export function getStatistics({ query, id }: GetEventsStatisticsRequestOptions): Promise<StatisticsResponse> {
  return request({
    url: `/events/${id}/daily-reports`,
    method: 'get',
    params: query
  });
}

export function exportEventsStatistics({ query, id }: GetEventsStatisticsRequestOptions): Promise<ExportResponse> {
  return request({
    url: `/events/${id}/daily-reports/export`,
    method: 'get',
    params: query,
    responseType: 'blob'
  });
}

export function exportEnrollments({ query, id }: GetEventsEnrollmentsRequestOptions): Promise<ExportResponse> {
  return request({
    url: `/events/${id}/enrollments/export`,
    method: 'get',
    params: query,
    responseType: 'blob'
  });
}

export function getEnrollments({ query, id }: GetEventsEnrollmentsRequestOptions): Promise<EnrollmentsResponse> {
  return request({
    url: `/events/${id}/enrollments`,
    method: 'get',
    params: query
  });
}

export function createEvent({ data }: CreateEventRequestOptions): Promise<SucceededResponse> {
  return request({
    url: '/events',
    method: 'post',
    data
  });
}

export function updateEvent({ data, id }: UpdateEventRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/events/${id}`,
    method: 'put',
    data
  });
}

export function deleteEvent({ id }: DeleteEventRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/events/${id}`,
    method: 'delete'
  });
}
