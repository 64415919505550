import { shallowRef } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const systemRouter: Array<RouteRecordRaw> = [
  {
    path: '/systems',
    component: shallowRef(Layout),
    redirect: '/systems/show-exposes',
    meta: {
      title: 'systems',
      icon: '#icon-admin',
      alwaysShow: true
    },
    children: [
      {
        path: 'show-exposes',
        component: () => import(/* webpackChunkName: "show-exposes" */ '@/views/show-exposes/List.vue'),
        name: 'list_show-exposes',
        meta: {
          title: 'showExposes',
          icon: '#icon-site'
        }
      },
      {
        path: 'show-exposes/create',
        component: () => import(/* webpackChunkName: "show-exposes" */ '@/views/show-exposes/Create.vue'),
        name: 'create_show-expose',
        meta: {
          activeMenu: '/system/show-exposes',
          hidden: true
        }
      },
      {
        path: 'show-exposes/edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "show-exposes" */ '@/views/show-exposes/Edit.vue'),
        name: 'edit_show-expose',
        meta: {
          activeMenu: '/system/show-exposes',
          hidden: true
        }
      }
    ]
  }
];

export default systemRouter;
