import { shallowRef } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const channelRouter: Array<RouteRecordRaw> = [
  {
    path: '/channels',
    component: shallowRef(Layout),
    redirect: '/channels',
    meta: {
      title: 'channels',
      icon: '#icon-channel'
    },
    children: [
      {
        path: 'analytics',
        component: () => import(/* webpackChunkName: "channel-create" */ '@/views/analytics-v2/List.vue'),
        name: 'list_channel-analytics',
        meta: {
          title: 'channelAnalytics',
          activeMenu: '/channels/analytics',
          icon: '#icon-analysis'
        }
      },
      {
        path: 'experience',
        component: () => import(/* webpackChunkName: "channel-create" */ '@/views/experience/List.vue'),
        name: 'list_channel-coupon-usage',
        meta: {
          title: 'channelExperience',
          activeMenu: '/channels/experience',
          icon: '#icon-tag'
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "channel-list" */ '@/views/channels/List.vue'),
        name: 'list_channels',
        meta: {
          title: 'channels',
          icon: '#icon-channel'
        }
      },
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "channel-create" */ '@/views/channels/Create.vue'),
        name: 'create_channel',
        meta: {
          title: 'createChannel',
          activeMenu: '/channels',
          hidden: true
        }
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "channel-edit" */ '@/views/channels/Edit.vue'),
        name: 'edit_channel',
        meta: {
          title: 'editChannel',
          noCache: true,
          activeMenu: '/channels',
          hidden: true
        }
      },
      {
        path: 'show/:id(\\d+)',
        component: () => import(/* webpackChunkName: "channel-show" */ '@/views/channels/Show.vue'),
        name: 'show_channel',
        meta: {
          title: 'showChannel',
          noCache: true,
          activeMenu: '/channels',
          hidden: true
        }
      },
      {
        path: 'campaigns',
        component: () => import(/* webpackChunkName: "channel-create" */ '@/views/campaigns/List.vue'),
        name: 'list_campaigns',
        meta: {
          title: 'campaigns',
          activeMenu: '/channels/campaigns',
          icon: '#icon-cooperation'
        }
      },
      {
        path: 'campaigns/modify/:id(\\d+)',
        component: () => import(/* webpackChunkName: "channel-create" */ '@/views/campaigns/Update.vue'),
        name: 'update_campaign',
        meta: {
          title: 'updateCampaigns',
          activeMenu: '/channels/campaigns',
          noCache: true,
          hidden: true
        }
      },
      {
        path: 'campaigns/create',
        component: () => import(/* webpackChunkName: "campaign-create" */ '@/views/campaigns/Create.vue'),
        name: 'create_campaign',
        meta: {
          title: 'createCampaign',
          noCache: true,
          activeMenu: '/channels/campaigns',
          hidden: true
        }
      },
      {
        path: 'campaigns/edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "channel-edit" */ '@/views/campaigns/Edit.vue'),
        name: 'edit_campaign',
        meta: {
          title: 'editCampaign',
          noCache: true,
          activeMenu: '/channels/campaigns',
          hidden: true
        }
      },
      {
        path: '/channel-agents/withdrawal',
        component: () => import(/* webpackChunkName: "channel-list" */ '@/views/channel-agent-withdrawals/List.vue'),
        name: 'list_channel-agent-withdrawals',
        meta: {
          title: 'channelAgentWithdrawals',
          icon: '#icon-review'
        }
      },
      {
        path: 'campaigns/:id(\\d+)/commission',
        component: () => import(/* webpackChunkName: "channel-create" */ '@/views/campaign-commissions/List.vue'),
        name: 'show_campaign-commission',
        meta: {
          title: 'campaignCommission',
          noCache: true,
          activeMenu: '/channels/campaigns',
          hidden: true
        }
      },
      {
        path: 'campaigns/:id(\\d+)/commission/setting',
        component: () => import(/* webpackChunkName: "campaign-edit" */ '@/views/campaign-commissions/Edit.vue'),
        name: 'edit_campaign-commission',
        meta: {
          title: 'editCampaignCommission',
          noCache: true,
          activeMenu: '/channels/campaigns',
          hidden: true
        }
      },
      {
        path: 'agents',
        component: () => import(/* webpackChunkName: "channelAgent-list" */ '@/views/channel-agents/List.vue'),
        name: 'list_channel-agents',
        meta: {
          title: 'channelAgents',
          icon: '#icon-admin'
        }
      },
      {
        path: 'agents/edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "channelAgent-edit" */ '@/views/channel-agents/Edit.vue'),
        name: 'edit_channel-agent',
        meta: {
          title: 'editChannelAgent',
          noCache: true,
          activeMenu: '/channels/agents',
          hidden: true
        }
      },
      {
        path: 'agents/create',
        component: () => import(/* webpackChunkName: "work-create" */ '@/views/channel-agents/Create.vue'),
        name: 'create_channel-agent',
        meta: {
          title: 'createChannelAgent',
          activeMenu: '/channels/agents',
          hidden: true
        }
      },
      {
        path: 'agents/show/:id(\\d+)',
        component: () => import(/* webpackChunkName: "channelAgent-show" */ '@/views/channel-agents/Show.vue'),
        name: 'show_channel-agent',
        meta: {
          title: 'showChannelAgent',
          noCache: true,
          activeMenu: '/channel/agents',
          hidden: true
        }
      }
    ]
  }
];

export default channelRouter;
