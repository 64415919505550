import { createHTTPClient } from '@/utils/http';

export const request = createHTTPClient({ baseURL: process.env.VUE_APP_BASE_API });

export function createFormData(object: Record<string, any> = {}) {
  const formData = new FormData();
  Object.entries(object)
    .forEach(([key, value]) => {
      formData.append(key, value);
    });

  return formData;
}
