import { request } from './utils';

import {
  LoginRequestOptions,
  LoginResponse,
  TOTPLoginRequestOptions,
  TOTPLoginResponse,
  LogoutResponse,
  AdminResponse
} from './interfaces';

export function login({ data }: LoginRequestOptions): Promise<LoginResponse> {
  return request({
    url: 'auth/login',
    method: 'post',
    data
  });
}

export function totpLogin({ data }: TOTPLoginRequestOptions): Promise<TOTPLoginResponse> {
  return request({
    url: 'auth/verify-totp',
    method: 'post',
    data
  });
}

export function logout(): Promise<LogoutResponse> {
  return request({
    url: '/auth/logout',
    method: 'get'
  });
}

export function getMe(): Promise<TOTPLoginResponse> {
  return request({
    url: '/auth/me',
    method: 'get'
  });
}
