import { request } from './utils';

import {
  OrdersResponse,
  GetOrdersRequestOptions,
  GetRefundRequestOptions,
  RefundResponse,
  OrderIdOption,
  UpdateRefundRequestOptions,
  GetOrderRequestOptions,
  OrderResponse,
  SucceededResponse
} from './interfaces';

export function getRefund({ orderId, query }: GetRefundRequestOptions): Promise<RefundResponse> {
  return request({
    url: `/orders/${orderId}/refund`,
    method: 'get',
    params: query
  });
}

export function getOrders({ query }: GetOrdersRequestOptions): Promise<OrdersResponse> {
  return request({
    url: '/orders',
    method: 'get',
    params: query
  });
}

export function updateRefund({ query, data }: UpdateRefundRequestOptions): Promise<OrdersResponse> {
  return request({
    url: `/orders/${query.orderId}/refund`,
    method: 'put',
    data
  });
}

export function getOrder({ orderId }: GetOrderRequestOptions): Promise<OrderResponse> {
  return request({
    url: `/orders/${orderId}`,
    method: 'get'
  });
}

export function syncOrder({ orderId }: OrderIdOption): Promise<SucceededResponse> {
  return request({
    url: `/orders/${orderId}/sync`,
    method: 'put'
  });
}
