export const FORM_DATE_HEADER = { 'Content-Type': 'multipart/form-data' };

export const MAX_PER_PAGE = 10000;
export const DEFAULT_PAGE_SIZE = 15;
export const DEFAULT_SIZE_LIMIT = 20971520;

export const UPDATED_SUCCESSFULLY_TEXT = '更新成功';
export const CREATED_SUCCESSFULLY_TEXT = '新增成功';
export const DELETED_SUCCESSFULLY_TEXT = '刪除成功';
export const VERIFY_SUCCESSFULLY_TEXT = '審核通過';
export const VERIFY_FAILED_TEXT = '審核失敗';
export const INCLUDE_SUCCESSFULLY_TEXT = '入選成功';
export const EXCLUDE_SUCCESSFULLY_TEXT = '淘汰成功';
export const VERIFYING_SUCCESSFULLY_TEXT = '回待審核成功';

/** https://docs.google.com/spreadsheets/d/1_94LDx6hYW_5QYcKznSuneoyGXPM1by2_mHKr3rgkW4/edit#gid=621990696 */
export const errorCodeMap = {
  100074: { message: '此代理商不存在，請選擇其他代理商' },
  201203: { message: '活動連結重複' },
  201301: { message: '代理商目前還有影片' },
  250001: { message: '後台 Auth 驗證失敗' },
  250002: { message: '後台 Auth Token 不合法' },
  250003: { message: '後台參數綁定錯誤' },
  250004: { message: '參數驗證錯誤' },
  250005: { message: '使用者沒有此權限' },
  250101: { message: '後台密碼驗證錯誤' },
  250102: { message: 'Goole Token 不合法' },
  250103: { message: 'Goole Token 更換失敗' },
  250104: { message: '後台註冊帳號重復' },
  250105: { message: '後台註冊帳號 ID 錯誤' },
  250106: { message: '後台發信失敗' },
  250107: { message: '後台管理者帳號重複' },
  250108: { message: '後台管理者密碼轉碼錯誤' },
  250109: { message: '後台註冊未滿 18' },
  250110: { message: '密碼 HASH 錯誤' },
  250111: { message: '優惠劵已版使用不得更改' },
  250112: { message: '作品不是發布狀態' },
  250113: { message: '作品不是下架狀態' },
  250114: { message: '廣告名稱重複' },
  250115: { message: '主題不得被刪除' },
  250116: { message: '使用者修改禁用狀態錯誤' },
  250117: { message: '用戶不存在' },
  250118: { message: '訂單已退款' },
  250119: { message: '禁止退款' },
  250120: { message: '計算退款金額失敗' },
  250121: { message: '禁止編輯過往資料' },
  250122: { message: '參數不存在' },
  250200: { message: '渠道名稱已存在，禁止新增相同錯誤' },
  250201: { message: '渠道不存在，禁止綁定合作關係' },
  250301: { message: '活動 ID 錯誤' },
  250302: { message: '建議 ID 錯誤' },
  250303: { message: '活動時間已有排程，請重新排程' },
  250401: { message: '時間區間設定錯誤' },
  250402: { message: '發送通知錯誤: 錯誤的站內通知類別' },
  250403: { message: '發送通知錯誤: 批次建立通知失敗' },
  250123: { message: '訂單禁止退款' },
  250124: { message: '禁止 30 秒內重複退款' },
  250501: { message: '熱門關鍵字 ID 錯誤' },
  250502: { message: '熱門關鍵字已存在' },
  250503: { message: '熱門關鍵字最多10組' },
  250504: { message: '推薦關鍵字ID錯誤' },
  250505: { message: '推薦關鍵字已存在' },
  250506: { message: '推薦關鍵字最多20組' },
  250507: { message: '影片不存在' },
  250008: { message: '註冊郵件重複' },
  250009: { message: '註冊帳號重複' },
  510001: { message: '廣告結束時間早於開始時間' },
  510002: { message: '同時間重疊之廣告過多' },
  // 訂單
  200801: { message: '該訂單不存在' },
  200802: { message: '檢查碼不相符' },
  200803: { message: '訂單已付款' },
  200804: { message: '訂單更新失敗' },
  200805: { message: '取消訂單訂閱失敗' },
  200806: { message: '未付款訂單在時限內，不可同步' },
  200807: { message: '恢復訂閱或退款失敗' },
  200808: { message: '無效的訂單狀態' },
  200809: { message: '查詢三方交易失敗' }
};
