import { createFormData, request } from './utils';

import {
  CreatePresignedUrlsRequestOptions,
  CreatePresignedUrlsResponse,
  CompleteMultipartUploadRequestOptions,
  CompleteMultipartUploadResponse,
  CreateConvertMediaJobRequestOptions,
  CreateConvertMediaJobResponse,
  UploadPhotoRequestOptions,
  UploadPhotoResponse
} from './interfaces';

export function createPresignedUrls({
  data
}: CreatePresignedUrlsRequestOptions): Promise<CreatePresignedUrlsResponse> {
  return request({
    url: '/medias/pre-signed-urls',
    method: 'post',
    data
  });
}

export function completeMultipartUpload({
  data
}: CompleteMultipartUploadRequestOptions): Promise<CompleteMultipartUploadResponse> {
  return request({
    url: '/medias/complete-multipart-upload',
    method: 'post',
    data
  });
}

export function createConvertMediaJob({
  data
}: CreateConvertMediaJobRequestOptions): Promise<CreateConvertMediaJobResponse> {
  return request({
    url: '/medias/convert-media',
    method: 'post',
    data
  });
}

export function uploadPhoto({
  data
}: UploadPhotoRequestOptions): Promise<UploadPhotoResponse> {
  return request({
    url: '/photos',
    method: 'post',
    data: createFormData(data)
  });
}
