import { request } from './utils';

import {
  GetConfigRequestOptions,
  UpdateConfigRequestOptions,
  SucceededResponse
} from './interfaces';

export function getConfig({ key }: GetConfigRequestOptions) {
  return request({
    url: `/configs/${key}`,
    method: 'get'
  });
}

export function updateConfig({ key, data }: UpdateConfigRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/configs/${key}`,
    method: 'put',
    data
  });
}
