import { shallowRef } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const blockRouter: Array<RouteRecordRaw> = [
  {
    path: '/blocks',
    component: shallowRef(Layout),
    meta: {
      title: 'block',
      icon: '#icon-block'
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "channel-create" */ '@/views/blocks/List.vue'),
        name: 'list_blocks',
        meta: {
          title: 'blocks',
          icon: '#icon-block'
        }
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "channel-create" */ '@/views/blocks/Edit.vue'),
        name: 'edit_blocks',
        meta: {
          title: 'editBlock',
          hidden: true
        }
      }
    ]
  }
];

export default blockRouter;
