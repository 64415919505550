import { shallowRef } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const worksRouter: Array<RouteRecordRaw> = [
  {
    path: '/works',
    component: shallowRef(Layout),
    redirect: '/works',
    meta: {
      title: 'works',
      icon: '#icon-video'
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "works-list" */ '@/views/works/List.vue'),
        name: 'list_works',
        meta: {
          title: 'worksList',
          icon: '#icon-work'
        }
      },
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "work-create" */ '@/views/works/Create.vue'),
        name: 'create_work',
        meta: {
          title: 'createWork',
          activeMenu: '/works',
          hidden: true
        }
      },
      // {
      //   path: 'batch-create',
      //   component: () => import(/* webpackChunkName: "work-batch-create" */ '@/views/works/BatchCreate.vue'),
      //   name: 'batch-create-work',
      //   meta: {
      //     title: 'batchCreateWork',
      //     activeMenu: '/works',
      //     hidden: true
      //   }
      // },
      {
        path: 'edit/:id',
        component: () => import(/* webpackChunkName: "work-edit" */ '@/views/works/Edit.vue'),
        name: 'edit_work',
        meta: {
          title: 'editWork',
          noCache: true,
          activeMenu: '/works',
          hidden: true
        }
      },
      {
        path: 'show/:id',
        component: () => import(/* webpackChunkName: "work-show" */ '@/views/works/Show.vue'),
        name: 'show_work',
        meta: {
          title: 'showWork',
          noCache: true,
          activeMenu: '/works',
          hidden: true
        }
      },
      // serial works
      // {
      //   path: '/serial-works',
      //   component: () => import(/* webpackChunkName: "serial-works-list" */ '@/views/serial-works/SeriesList.vue'),
      //   name: 'list_serial-works',
      //   meta: {
      //     title: 'serialWorks',
      //     icon: '#icon-work'
      //   }
      // },
      // {
      //   path: '/serial-works/seasons/:id',
      //   component: () => import(/* webpackChunkName: "serial-works-seasons-list" */ '@/views/serial-works/List.vue'),
      //   name: 'list_seasons',
      //   meta: {
      //     title: 'serialWorks',
      //     icon: '#icon-work',
      //     hidden: true
      //   }
      // },
      // {
      //   path: '/serial-works/create',
      //   component: () => import(/* webpackChunkName: "serial-work-create" */ '@/views/serial-works/Create.vue'),
      //   name: 'create_serial-work',
      //   meta: {
      //     title: 'createWork',
      //     activeMenu: '/serial-works',
      //     hidden: true
      //   }
      // },
      // {
      //   path: '/serial-works/batch-create',
      //   component: () => import(/* webpackChunkName: "serial-work-batch-create" */ '@/views/serial-works/BatchCreate.vue'),
      //   name: 'batch-create-serial-works',
      //   meta: {
      //     title: 'batchCreateWork',
      //     activeMenu: '/serial-works',
      //     hidden: true
      //   }
      // },
      // {
      //   path: '/serial-works/edit/:id',
      //   component: () => import(/* webpackChunkName: "serial-work-edit" */ '@/views/serial-works/Edit.vue'),
      //   name: 'edit_serial-work',
      //   meta: {
      //     title: 'editWork',
      //     noCache: true,
      //     activeMenu: '/serial-works',
      //     hidden: true
      //   }
      // },
      // {
      //   path: '/serial-works/show/:id',
      //   component: () => import(/* webpackChunkName: "serial-work-show" */ '@/views/serial-works/Show.vue'),
      //   name: 'show_serial-work',
      //   meta: {
      //     title: 'showWork',
      //     noCache: true,
      //     activeMenu: '/serial-works',
      //     hidden: true
      //   }
      // }
      {
        path: 'agents',
        component: () => import(/* webpackChunkName: "works-list" */ '@/views/agents/List.vue'),
        name: 'list_agents',
        meta: {
          title: 'agentsList',
          icon: '#icon-support'
        }
      }
    ]
  }
];

export default worksRouter;
