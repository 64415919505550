import { shallowRef } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const revenueRouter: Array<RouteRecordRaw> = [
  {
    path: '/analytics/daily',
    component: shallowRef(Layout),
    // redirect: 'noRedirect',
    meta: {
      title: 'analytics',
      icon: '#icon-analysis',
      hidden: true
    },
    children: [
      {
        path: 'revenue',
        component: () => import(/* webpackChunkName: "revenue-daily" */ '@/views/analytics/revenue/Index.vue'),
        name: 'list_daily-revenue',
        meta: {
          title: 'DailyRevenue',
          icon: '#icon-analysis'
        }
      },
      {
        path: 'channel',
        component: () => import(/* webpackChunkName: "channel-daily" */ '@/views/analytics/channel/Index.vue'),
        name: 'list_daily-channel',
        meta: {
          title: 'DailyChannel',
          icon: '#icon-analysis'
        }
      },
      {
        path: 'campaign',
        component: () => import(/* webpackChunkName: "channel-daily" */ '@/views/analytics/campaign/Index.vue'),
        name: 'list_daily-campaign',
        meta: {
          title: 'DailyCampaign',
          icon: '#icon-analysis'
        }
      }
    ]
  }
];

export default revenueRouter;
