/* eslint-disable @typescript-eslint/camelcase */
/*
 * @Description:
 * @Author: ZY
 * @Date: 2020-12-28 17:18:24
 * @LastEditors: ZY
 * @LastEditTime: 2021-01-20 15:18:01
 */

export default {
  route: {
    dashboard: '首頁',
    permissions: '權限',
    admins: '帳號',
    createAdmin: '新增管理者帳號',
    editAdmin: '編輯管理者帳號',
    roles: '角色',
    plans: '方案',
    plansAndSubscriptions: '訂閱方案',
    subscriptions: '訂閱',
    subscriptionsCancellations: '取消續訂記錄',
    coupons: '體驗券',
    orders: '訂單',
    issues: '用戶問題',
    replyIssue: '問題回覆',
    reviews: '影片審核',
    showReview: '影片詳情',
    works: '影片',
    worksList: '單部影片',
    agentsList: '代理商設定',
    createWork: '上傳影片',
    batchCreateWork: '批量上傳',
    editWork: '編輯影片',
    showWork: '影片詳情',
    serialWorks: '系列影片',
    directors: '導演',
    genres: '影片類型',
    createGenre: '新增影片類型',
    editGenre: '編輯影片類型',
    editDirector: '編輯導演',
    createDirector: '新增導演',
    tags: '標籤',
    createTag: '新增標籤',
    editTag: '編輯標籤',
    categories: '影片類型',
    actors: '演員',
    createActor: '新增演員',
    editActor: '編輯演員',
    banners: '宣傳版位',
    agents: '代理商',
    createAgent: '新增代理商',
    editAgent: '編輯代理商',
    showAgent: '代理商（詳細資料）',
    eventList: '活動列表頁',
    createEvent: '新增活動頁',
    editEvent: '編輯活動頁',
    createPosition: '新增版位',
    editPosition: '編輯版位',
    enrollmentsList: '報名資料',
    statisticsList: '活動數據頁',
    advertisementsList: '廣告',
    createAdvertisement: '新增廣告',
    editAdvertisement: '編輯廣告',
    listAnalytics: '廣告數據統計',
    dictionary: '詞典',
    errorPages: '頁面錯誤',
    page401: '401',
    page404: '404',
    users: '會員',
    topics: '主題',
    createTopic: '新增主題',
    editTopic: '編輯主題',
    createUser: '新增會員',
    editUser: '編輯會員',
    showUser: '會員詳情',
    reviewUser: '年齡審核',
    'browser-videos-history': '用戶觀看紀錄',
    editPlan: '編輯方案',
    createCoupon: '新增兌換券',
    editCoupon: '編輯兌換券',
    channels: '渠道',
    channelAnalytics: '渠道流量統計',
    campaigns: '渠道合作',
    createChannel: '建立渠道',
    channelExperience: '渠道體驗卷統計',
    editChannel: '編輯渠道',
    createCampaign: '建立渠道合作',
    editCampaign: '編輯渠道合作',
    showChannel: '渠道詳情',
    createRole: '新增角色',
    editRole: '編輯角色',
    updateCampaigns: '校正合作模式',
    wte: 'WTE管理',
    events: '活動',
    suggestion: '建議',
    'events-statistics': '活動數據',
    'events-create': '新增活動',
    'events-edit': '編輯活動',
    'events-show': '活動詳情',
    editSuggestion: '審核',
    showSuggestion: '詳情',
    operationLogs: '操作日誌',
    showOrder: '收據詳情',
    withdrawals: '提領批核',
    announcements: '站內通知',
    createAnnouncement: '新增通知',
    editAnnouncement: '編輯通知',
    showAnnouncement: '通知詳情',
    search: '搜尋功能',
    keywords: '關鍵字推薦影片',
    createKeyword: '新增關鍵字推薦影片',
    'hot-keywords': '熱門關鍵字',
    createHotKeyword: '新增熱門關鍵字',
    'recommended-topic': '推薦主題設定',
    'search-statistics': '數據',
    'keywords-statistics': '搜尋數據',
    'popular-keyword-statistics': '熱門關鍵字',
    'recommended-keyword-statistics': '推薦關鍵字',
    'organic-keyword-statistics': '自然搜尋',
    'work-statistics': '影片播放數',
    block: '場館',
    blocks: '場館',
    editBlock: '場館編輯',
    channelAgentWithdrawals: '提領批核',
    campaignCommission: '渠道合作分潤',
    editCampaignCommission: '設定渠道合作分潤',
    channelAgents: '代理管理者',
    editChannelAgent: '編輯代理管理者',
    createChannelAgent: '新增代理管理者',
    showChannelAgent: '代理管理者詳情',
    systems: '系統設定',
    showExposes: '開啟黃色頁'
  },
  navbar: {
    dashboard: '首頁',
    logOut: '登出'
  },
  login: {
    logIn: '登入',
    username: '帳號',
    password: '密碼'
  },
  workStatus: {
    draft: '草稿',
    reviewing: '審核中',
    processing: '處理中',
    published: '上架',
    rejected: '駁回',
    expired: '到期',
    taken_down: '下架'
  },
  announcementStatus: {
    published: '已發佈',
    expired: '已過期',
    scheduling: '待發佈'
  },
  announcementType: {
    system: '系統',
    event: '活動'
  },
  announcementTargetUsers: {
    all: '全部會員',
    new: '新會員',
    specific: '指定會員'
  },
  advertisementStatus: {
    published: '在線',
    expired: '過期'
  },
  couponStatus: {
    scheduling: '未上架',
    run_out: '兌換完畢',
    published: '上架',
    expired: '過期',
    taken_down: '下架'
  },
  selectOption: {
    yesterday: '昨日',
    last3days: '近3天',
    last7days: '近7天'
  },
  orderStatus: {
    unpaid: '尚未付款',
    expired: '訂單逾期',
    fail: '付款失敗',
    success: '付款成功',
    refunded: '已退款',
    refunding: '等待退款'
  },
  orderPaymentMethod: {
    credit_card: '信用卡'
  },
  subscriptionSource: {
    plan: '會員訂閱',
    coupon: '優惠券',
    testing: '後台建立',
    nft: '開通NFT會員'
  },
  gender: {
    female: '女',
    male: '男',
    FEMALE: '女',
    MALE: '男'
  },
  topicType: {
    custom: '自訂',
    exclusive: '麻豆獨家',
    billboard: '前十大熱榜',
    recommend: '專屬推薦',
    newest: '最新上映',
    watch: '觀看紀錄',
    favorite: '最愛',
    coming_soon: '即將上映'
  },
  topicStyle: {
    medium: '小圖',
    large: '大圖',
    billboard: '排行',
    image_text: '圖文'
  },
  adminStatus: {
    enabled: '啟用',
    disabled: '禁用'
  },
  channelAgentWithdrawalStatus: {
    auditing: '審核中',
    failed: '支出失敗',
    success: '支出成功'
  }
};
