import { request } from './utils';

import {
  GetClassificationsRequestOptions,
  ClassificationsResponse
} from './interfaces';

export function getClassifications({ query }: GetClassificationsRequestOptions = {}): Promise<ClassificationsResponse> {
  return request({
    url: '/classifications',
    method: 'get',
    params: query
  });
}
