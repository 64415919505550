import { request } from './utils';

import {
  UsersReviewResponse,
  GetUsersReviewRequestOptions,
  UpdateUserReviewRequestOptions
} from './interfaces';

export function getUsersReview({ query }: GetUsersReviewRequestOptions): Promise<UsersReviewResponse> {
  return request({
    url: '/users/reviews',
    method: 'get',
    params: query
  });
}

export function updateUserReview({ userId, data }: UpdateUserReviewRequestOptions): Promise<UsersReviewResponse> {
  return request({
    url: `/users/reviews/${userId}`,
    method: 'post',
    data
  });
}
