/*
 * @Description:
 * @Author: ZY
 * @Date: 2020-12-28 11:26:33
 * @LastEditors: ZY
 * @LastEditTime: 2020-12-28 13:34:08
 */
import { AdminStatus, Role } from '@/interfaces';
import { getToken } from '@/utils/cookies';

export interface UserState{
  id: number
  username: string
  name: string
  phone: string
  status: AdminStatus
  roles: Role[]
  token: string
  createdAt: string
}

export const state: UserState = {
  id: null,
  username: '',
  name: '',
  phone: '',
  status: null,
  roles: [],
  createdAt: '',
  token: getToken() || ''
};
