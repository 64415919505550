import { request } from './utils';

import {
  AnnouncementResponse,
  AnnouncementsResponse,
  GetAnnouncementRequestOptions,
  GetAnnouncementsRequestOptions,
  CreateAnnouncementRequestOptions,
  UpdateAnnouncementRequestOptions,
  SucceededResponse,
  GetAnnouncementSpecifiedUsersRequestOptions,
  AnnouncementSpecifiedUsersResponse
} from './interfaces';

export function getAnnouncement({ announcementId }: GetAnnouncementRequestOptions): Promise<AnnouncementResponse> {
  return request({
    url: `/announcements/${announcementId}`,
    method: 'get'
  });
}

export function getAnnouncements({ query }: GetAnnouncementsRequestOptions = {}): Promise<AnnouncementsResponse> {
  return request({
    url: '/announcements',
    method: 'get',
    params: query
  });
}

export function createAnnouncement({ data }: CreateAnnouncementRequestOptions): Promise<SucceededResponse> {
  return request({
    url: '/announcements',
    method: 'post',
    data
  });
}

export function updateAnnouncement({ announcementId, data }: UpdateAnnouncementRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/announcements/${announcementId}`,
    method: 'put',
    data
  });
}

export function patchAnnouncement({ announcementId, data }: UpdateAnnouncementRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/announcements/${announcementId}`,
    method: 'patch',
    data
  });
}

export function getAnnouncementSpecifiedUsers({ query, announcementId }: GetAnnouncementSpecifiedUsersRequestOptions): Promise<AnnouncementSpecifiedUsersResponse> {
  return request({
    url: `/announcements/${announcementId}/specified-users`,
    method: 'get',
    params: query
  });
}
