import { shallowRef } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const planRouter: Array<RouteRecordRaw> = [
  {
    path: '/plans',
    component: shallowRef(Layout),
    redirect: '/plans',
    meta: {
      title: 'plansAndSubscriptions',
      icon: '#icon-plan'
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "plan-list" */ '@/views/plans/List.vue'),
        name: 'list_plans',
        meta: {
          title: 'plans',
          icon: '#icon-plan'
        }
      },
      // {
      //   path: 'create',
      //   component: () => import(/* webpackChunkName: "plan-create" */ '@/views/plans/Create.vue'),
      //   name: 'create_plan',
      //   meta: {
      //     title: 'createPlan',
      //     activeMenu: '/plans',
      //     hidden: true
      //   }
      // },
      {
        path: 'edit/:id',
        component: () => import(/* webpackChunkName: "plan-edit" */ '@/views/plans/Edit.vue'),
        name: 'edit_plan',
        meta: {
          title: 'editPlan',
          noCache: true,
          activeMenu: '/plans',
          hidden: true
        }
      },
      // {
      //   path: ':id(\\d+)',
      //   component: () => import(/* webpackChunkName: "plan-show" */ '@/views/plans/Show.vue'),
      //   name: 'show_plan',
      //   meta: {
      //     title: 'showPlan',
      //     noCache: true,
      //     activeMenu: '/plans',
      //     hidden: true
      //   }
      // },
      {
        path: 'coupons',
        component: () => import(/* webpackChunkName: "coupon-list" */ '@/views/coupons/List.vue'),
        name: 'list_coupons',
        meta: {
          title: 'coupons',
          icon: '#icon-promotion'
        }
      },
      {
        path: 'coupons/create',
        component: () => import(/* webpackChunkName: "coupon-create" */ '@/views/coupons/Create.vue'),
        name: 'create_coupon',
        meta: {
          title: 'createCoupon',
          activeMenu: '/plans/coupons',
          hidden: true
        }
      },
      {
        path: 'coupons/edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "coupon-edit" */ '@/views/coupons/Edit.vue'),
        name: 'edit_coupon',
        meta: {
          title: 'editCoupon',
          noCache: true,
          activeMenu: '/plans/coupons',
          hidden: true
        }
      },
      {
        path: 'subscriptions',
        component: () => import(/* webpackChunkName: "subscription-list" */ '@/views/subscriptions/List.vue'),
        name: 'list_subscriptions',
        meta: {
          title: 'subscriptions',
          activeMenu: '/plans/subscriptions',
          icon: '#icon-subscription'
        }
      },
      {
        path: 'subscription-cancellations',
        component: () => import(/* webpackChunkName: "subscription-list" */ '@/views/subscriptions/cancellations.vue'),
        name: 'list_subscription-cancellations',
        meta: {
          title: 'subscriptionsCancellations',
          activeMenu: '/plans/subscription/cancellations',
          icon: '#icon-subscription'
        }
      }
    ]
  }
];

export default planRouter;
