
import { DeviceType } from '@/store/modules/app/state';
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, onMounted, reactive, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
import { AppActionTypes } from '@/store/modules/app/action-types';
import { AppMain, Navbar, Settings, TagsView, Sidebar } from './components';
import RightPanel from '@/components/right_panel/Index.vue';
import resize from './resize';
export default defineComponent({
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    RightPanel,
    Settings,
    Sidebar
    // TagsView
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const cachedViews = () => {
      return store.state.tagViews.cachedViews;
    };

    const { sidebar, device, addEventListenerOnResize, resizeMounted, removeEventListenerResize, watchRouter } = resize();
    const state = reactive({
      handleClickOutside: () => {
        store.dispatch(AppActionTypes.ACTION_CLOSE_SIDEBAR, false);
      }
    });

    const classObj = computed(() => {
      return {
        hideSidebar: !sidebar.value.opened,
        openSidebar: sidebar.value.opened,
        withoutAnimation: sidebar.value.withoutAnimation,
        mobile: device.value === DeviceType.Mobile
      };
    });

    const showSettings = computed(() => {
      return store.state.settings.showSettings;
    });
    const showTagsView = computed(() => {
      return store.state.settings.showTagsView;
    });
    const fixedHeader = computed(() => {
      return store.state.settings.fixedHeader;
    });

    watchRouter();
    onBeforeMount(() => {
      addEventListenerOnResize();
    });

    onMounted(() => {
      resizeMounted();
    });

    onBeforeUnmount(() => {
      removeEventListenerResize();
    });
    return {
      cachedViews,
      t,
      classObj,
      sidebar,
      showSettings,
      showTagsView,
      fixedHeader,
      ...toRefs(state)
    };
  }
});
