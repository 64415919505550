import { request } from './utils';

import {
  RecommendKeywordsResponse,
  GetRecommendKeywordsRequestOptions,
  CreateRecommendKeywordRequestOptions,
  UpdateRecommendKeywordPriorityRequestOptions,
  DeleteRecommendKeywordRequestOptions,
  SucceededResponse
} from './interfaces';

export function getRecommendKeywords({ query }: GetRecommendKeywordsRequestOptions = {}): Promise<RecommendKeywordsResponse> {
  return request({
    url: '/recommend-keywords',
    method: 'get',
    params: query
  });
}

export function createRecommendKeyword({ data }: CreateRecommendKeywordRequestOptions): Promise<SucceededResponse> {
  return request({
    url: '/recommend-keywords',
    method: 'post',
    data
  });
}

export function updateRecommendKeywordPriority({ data }: UpdateRecommendKeywordPriorityRequestOptions): Promise<SucceededResponse> {
  return request({
    url: '/recommend-keywords/prioritize',
    method: 'put',
    data
  });
}

export function deleteRecommendKeyword({ keywordId }: DeleteRecommendKeywordRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/recommend-keywords/${keywordId}`,
    method: 'delete'
  });
}
