import { shallowRef } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const userRouter: Array<RouteRecordRaw> = [
  {
    path: '/wte',
    component: shallowRef(Layout),
    redirect: '/wte/event',
    meta: {
      title: 'wte',
      icon: '#icon-wte'
    },
    children: [
      {
        path: 'event',
        component: () => import(/* webpackChunkName: "events-list" */ '@/views/wte-events/List.vue'),
        name: 'list_wte_events',
        meta: {
          title: 'events',
          icon: '#icon-event'
        }
      },
      {
        path: 'event/create',
        component: () => import(/* webpackChunkName: "suggestion-list" */ '@/views/wte-events/Create.vue'),
        name: 'create_wte_event',
        meta: {
          title: 'events-create',
          hidden: true,
          activeMenu: '/wte/event'
        }
      },
      {
        path: 'event/:id/edit',
        component: () => import(/* webpackChunkName: "suggestion-list" */ '@/views/wte-events/Edit.vue'),
        name: 'edit_wte_event',
        meta: {
          title: 'events-edit',
          hidden: true,
          activeMenu: '/wte/event'
        }
      },
      {
        path: 'event/:id/show',
        component: () => import(/* webpackChunkName: "suggestion-list" */ '@/views/wte-events/Show.vue'),
        name: 'show_wte_event',
        meta: {
          title: 'events-show',
          hidden: true,
          activeMenu: '/wte/event'
        }
      },
      {
        path: 'suggestion',
        component: () => import(/* webpackChunkName: "suggestion-list" */ '@/views/wte-suggestion/List.vue'),
        name: 'list_suggestions',
        meta: {
          title: 'suggestion',
          icon: '#icon-suggestion'
        }
      },
      {
        path: '/wte/suggestion/edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "suggestion-edit" */ '@/views/wte-suggestion/Edit.vue'),
        name: 'edit_suggestion',
        meta: {
          title: 'editSuggestion',
          noCache: true,
          activeMenu: '/wte/suggestion',
          hidden: true
        }
      },
      {
        path: '/wte/suggestion/show/:id(\\d+)',
        component: () => import(/* webpackChunkName: "suggestion-edit" */ '@/views/wte-suggestion/Show.vue'),
        name: 'show_suggestion',
        meta: {
          title: 'showSuggestion',
          noCache: true,
          activeMenu: '/wte/suggestion',
          hidden: true
        }
      },
      {
        path: 'event-statistics',
        component: () => import(/* webpackChunkName: "event-statistics" */ '@/views/wte-event-statistics/List.vue'),
        name: 'list_wte-statistics',
        meta: {
          title: 'events-statistics',
          icon: '#icon-analysis'
        }
      },
      {
        path: 'withdrawals',
        component: () => import(/* webpackChunkName: "withdraw-list" */ '@/views/wte-withdraw/List.vue'),
        name: 'list_wte-withdrawals',
        meta: {
          title: 'withdrawals',
          icon: '#icon-review'
        }
      }
    ]
  }
];

export default userRouter;
