import { request, createFormData } from './utils';

import {
  GetFeatureWorksRequestOptions,
  // CreateFeatureWorkRequestOptions,
  UpdateBlockFeatureWorkRequestOptions,
  FeatureWorksResponse,
  // FeatureWorkResponse,
  SucceededResponse
  // DeleteFeatureWorkRequestOptions,
} from './interfaces';

// export function getFeatureWork({
//   feature-workId
// }: GetFeatureWorkRequestOptions): Promise<FeatureWorkResponse> {
//   return request({
//     url: `/feature-works/${feature-workId}`,
//     method: 'get'
//   });
// }

export function getFeatureWorks({
  query
}: GetFeatureWorksRequestOptions): Promise<FeatureWorksResponse> {
  return request({
    url: '/feature-works',
    method: 'get',
    params: query
  });
}

// export function createFeatureWork({
//   data
// }: CreateFeatureWorkRequestOptions): Promise<FeatureWorksResponse> {
//   return request({
//     url: '/feature-works',
//     method: 'post',
//     data: createFormData(data)
//   });
// }

export function updateBlockFeatureWork({ query, data }: UpdateBlockFeatureWorkRequestOptions): Promise<FeatureWorksResponse> {
  return request({
    url: '/feature-works',
    method: 'put',
    params: query,
    data
  });
}

// export function deleteFeatureWork({
//   feature-workId
// }: DeleteFeatureWorkRequestOptions): Promise<SucceededResponse> {
//   return request({
//     url: `/feature-works/${feature-workId}`,
//     method: 'delete'
//   });
// }
