import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
// import { useI18n } from 'vue-i18n'
import router from '@/router';
import { RouteLocationNormalized } from 'vue-router';
import { useStore } from './store';
import { UserActionTypes } from './store/modules/user/action-types';
import { PermissionActionType } from './store/modules/permission/action-types';
import { ElMessage } from 'element-plus';
import whiteList from './config/default/whitelist';
import { union } from 'lodash';

import {
  getPermissions,
  setPermissions
} from '@/utils/local-storage';
import { getMe } from './services/api';

// import settings from '@/config/default/setting.config'
NProgress.configure({ showSpinner: false });

// const getPageTitle = (key: string) => {
//   const i18n = useI18n()
//   const title = settings.title
//   const hasKey = i18n.te(`route.${key}`)
//   if (hasKey) {
//     const pageName = i18n.t(`route.${key}`)
//     return `${pageName} - ${title}`
//   }
//   return `${title}`
// }

router.beforeEach(
  async(
    to: RouteLocationNormalized,
    _: RouteLocationNormalized,
    next: any
  ) => {
    // Start progress bar
    NProgress.start();

    const store = useStore();
    // Determine whether the user has logged in
    if (!store.state.user.token) {
      // Has no token
      if (whiteList.includes(to.path)) {
        // In the free login whitelist, go directly
        next();
        return;
      }

      // Other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`);
      NProgress.done();
      return;
    }

    // is logged in
    if (to.path === '/login') {
      // If is logged in, redirect to the home page
      next({ path: '/' });
      NProgress.done();
      return;
    }

    if (to.path.startsWith('/4')) {
      next();
      NProgress.done();
      return;
    }

    // Check whether the user has obtained his permission array
    if (!store.state.permission.dynamicRoutes.length) {
      try {
        // await store.dispatch(UserActionTypes.ACTION_GET_USER_INFO, undefined);
        const { data: me } = await getMe();

        let myPermissions = [];
        me.roles.forEach((role) => {
          myPermissions = union(myPermissions, role.permissions);
        });

        // sync local storage
        setPermissions(myPermissions);

        // Generate accessible routes map based on permissions
        store.dispatch(PermissionActionType.ACTION_SET_ROUTES, myPermissions);

        store.dispatch(UserActionTypes.ACTION_GET_USER_INFO, me.roles);

        // Dynamically add accessible routes
        store.state.permission.dynamicRoutes.forEach((route) => {
          router.addRoute(route);
        });

        // current admin has no accessible routes
        if (!store.state.permission.dynamicRoutes.length) {
          next('/401');
          NProgress.done();
          return;
        }

        // Hack: ensure addRoutes is complete
        // Set the replace: true, so the navigation will not leave a history record
        next({ ...to, replace: true });
      } catch (error) {
        // Remove token and redirect to login page
        store.dispatch(UserActionTypes.ACTION_RESET_TOKEN, undefined);

        console.error('router.beforeEach_error', error);
        ElMessage.error(error);

        next(`/login?redirect=${to.path}`);
        NProgress.done();
      }

      return;
    }

    next();
  }
);

router.afterEach((to: RouteLocationNormalized) => {
  console.log({ currentRoute: to });
  // Finish progress bar
  // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
  NProgress.done();

  // set page title
  // document.title = getPageTitle(to.meta.title)
});
