import { request } from './utils';

import {
  OperationLogsResponse,
  GetOperationLogsRequestOptions,
  OperationResourcesResponse,
  GetOperationResourcesRequestOptions
} from './interfaces';

export function getOperationLogs({ query }: GetOperationLogsRequestOptions): Promise<OperationLogsResponse> {
  return request({
    url: '/operation-logs',
    method: 'get',
    params: query
  });
}

export function getOperationResources({ query }: GetOperationResourcesRequestOptions): Promise<OperationResourcesResponse> {
  return request({
    url: '/operation-resources',
    method: 'get',
    params: query
  });
}
