import { shallowRef } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const announcementRouter: Array<RouteRecordRaw> = [
  {
    path: '/announcements',
    component: shallowRef(Layout),
    // redirect: 'noredirect',
    meta: {
      title: 'announcements',
      icon: '#icon-notification'
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "announcement-list" */ '@/views/announcements/List.vue'),
        name: 'list_announcements',
        meta: {
          title: 'announcements',
          icon: '#icon-notification'
        }
      },
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "example-create" */ '@/views/announcements/Create.vue'),
        name: 'create_announcement',
        meta: {
          title: 'createAnnouncement',
          noCache: true,
          activeMenu: '/announcements',
          hidden: true
        }
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "example-edit" */ '@/views/announcements/Edit.vue'),
        name: 'edit_announcement',
        meta: {
          title: 'editAnnouncement',
          noCache: true,
          activeMenu: '/announcements',
          hidden: true
        }
      },
      {
        path: 'show/:id(\\d+)',
        component: () => import(/* webpackChunkName: "announcement-show" */ '@/views/announcements/Show.vue'),
        name: 'show_announcement',
        props: (route) => (route.params),
        meta: {
          title: 'showAnnouncement',
          noCache: true,
          activeMenu: '/announcements',
          hidden: true
        }
      }
    ]
  }
];

export default announcementRouter;
