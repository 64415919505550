/*
 * @Description: app Mutations
 * @Author: ZY
 * @Date: 2020-12-23 10:25:37
 * @LastEditors: ZY
 * @LastEditTime: 2020-12-28 11:45:02
 */
import { MutationTree } from 'vuex';
import { UserState } from './state';
import { UserMutationTypes } from './mutation-types';
import { AdminStatus, Role } from '@/interfaces';

export type Mutations<S = UserState> = {
  [UserMutationTypes.SET_ID](state: S, id: number): void
  [UserMutationTypes.SET_USERNAME](state: S, username: string): void
  [UserMutationTypes.SET_NAME](state: S, name: string): void
  [UserMutationTypes.SET_PHONE](state: S, phone: string): void
  [UserMutationTypes.SET_STATUS](state: S, status: AdminStatus): void
  [UserMutationTypes.SET_ROLES](state: S, roles: Role[]): void
  [UserMutationTypes.SET_TOKEN](state: S, token: string): void
  [UserMutationTypes.SET_CREATED_AT](state: S, createdAt: string): void
}

export const mutations: MutationTree<UserState> & Mutations = {
  [UserMutationTypes.SET_ID](state: UserState, id: number) {
    state.id = id;
  },
  [UserMutationTypes.SET_USERNAME](state: UserState, username: string) {
    state.username = username;
  },
  [UserMutationTypes.SET_NAME](state: UserState, name: string) {
    state.name = name;
  },
  [UserMutationTypes.SET_PHONE](state: UserState, phone: string) {
    state.phone = phone;
  },
  [UserMutationTypes.SET_STATUS](state: UserState, status: AdminStatus) {
    state.status = status;
  },
  [UserMutationTypes.SET_ROLES](state: UserState, roles: Role[]) {
    state.roles = roles;
  },
  [UserMutationTypes.SET_TOKEN](state: UserState, token: string) {
    state.token = token;
  },
  [UserMutationTypes.SET_CREATED_AT](state: UserState, createdAt: string) {
    state.createdAt = createdAt;
  }
};
