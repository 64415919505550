import { request } from './utils';

import {
  GetWteEventListRequestOptions,
  GetWteEventListResponse,
  GetWteEventRequestOptions,
  GetWteEventResponse,
  UpdateWteEventRequestOptions,
  CreateWteEventRequestOptions,
  GetEventStatisticsRequestOptions,
  GetEventStatisticsResponse,
  GetEventUserStatisticsRequestOptions,
  GetEventUserStatisticsResponse,
  ExportEventStatistics,
  ExportEventStatisticsResponse,
  ExportEventDataDetail
} from './interfaces';
import { forEach } from 'lodash';

export function getWteEventList({ query }: GetWteEventListRequestOptions): Promise<GetWteEventListResponse> {
  const formattedQuery = query;

  forEach(formattedQuery, (q, key) => {
    if (q) return;
    delete formattedQuery[key];
  });

  return request({
    url: '/wte/events',
    method: 'get',
    params: formattedQuery
  });
}

export function getWteEvent({ eventId }: GetWteEventRequestOptions): Promise<GetWteEventResponse> {
  return request({
    url: `/wte/events/${eventId}`,
    method: 'get'
  });
}

export function updateWteEvent({ eventId, data }: UpdateWteEventRequestOptions): Promise<GetWteEventResponse> {
  return request({
    url: `/wte/events/${eventId}`,
    method: 'put',
    data
  });
}

export function createWteEvent({ data }: CreateWteEventRequestOptions): Promise<GetWteEventResponse> {
  return request({
    url: '/wte/events',
    method: 'post',
    data
  });
}

export function getEventStatistics({ query }: GetEventStatisticsRequestOptions): Promise<GetEventStatisticsResponse> {
  Object.keys(query).forEach((key) => {
    if (!query[key]) delete query[key];
  });
  return request({
    url: '/wte/event-statistics',
    method: 'get',
    params: query
  });
}

export function getEventUserStatistics({ query }: GetEventUserStatisticsRequestOptions): Promise<GetEventUserStatisticsResponse> {
  return request({
    url: `/wte/event-statistics/${query.wteEventId}/user-statistics`,
    method: 'get',
    params: query
  });
}

export function exportEventStatistics({ query }: ExportEventStatistics): Promise<ExportEventStatisticsResponse> {
  return request({
    url: '/wte/event-statistics/export',
    method: 'get',
    params: query,
    responseType: 'blob'
  });
}

export function exportEventUserStatistics({ query }: ExportEventDataDetail): Promise<ExportEventStatisticsResponse> {
  return request({
    url: `/wte/event-statistics/${query.eventId}/user-statistics/export`,
    method: 'get',
    params: query,
    responseType: 'blob'
  });
}
