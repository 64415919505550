import { request } from './utils';

import {
  ShowExposesResponse,
  ShowExposeResponse,
  GetShowExposesRequestOptions,
  CreateShowExposesRequestOptions,
  UpdateShowExposesRequestOptions
} from './interfaces';

import { ScheduleStatus } from '@/services/api';

export function getShowExposes({ query }: GetShowExposesRequestOptions): Promise<ShowExposesResponse> {
  return request({
    url: '/systems/show-expose',
    method: 'get',
    params: query
  });
}

export function getShowExpose(id: string): Promise<ShowExposeResponse> {
  return request({
    url: `/systems/show-expose/${id}`,
    method: 'get'
  });
}

export function createShowExposes({ data }: CreateShowExposesRequestOptions): Promise<ShowExposeResponse> {
  return request({
    url: '/systems/show-expose/create',
    method: 'post',
    data
  });
}

export function updateShowExposes({ id, data }: UpdateShowExposesRequestOptions): Promise<ShowExposeResponse> {
  return request({
    url: `/systems/show-expose/${id}`,
    method: 'put',
    data
  });
}

export function deleteShowExposes(id: number): Promise<ShowExposeResponse> {
  return request({
    url: `/systems/show-expose/${id}`,
    method: 'delete'
  });
}

export function toggleShowExposes(data: { status?: ScheduleStatus, enable: boolean }): Promise<ShowExposeResponse> {
  return request({
    url: '/systems/show-expose/all/toggle',
    method: 'post',
    data
  });
}

export function toggleShowExpose(id: number): Promise<ShowExposeResponse> {
  return request({
    url: `/systems/show-expose/${id}/toggle`,
    method: 'post'
  });
}
