import { request } from './utils';

import {
  LanguagesResponse
} from './interfaces';

export function getLanguages(): Promise<LanguagesResponse> {
  return request({
    url: '/languages',
    method: 'get'
  });
}
