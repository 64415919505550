import { request } from './utils';

import {
  GetAnalyticRequestOptions,
  AnalyticResponse,
  RevenueDailyResponse,
  DashboardChannelListResponse,
  GetDailyCampaignStatisticsRequestOptions,
  DailyCampaignStatisticsResponse,
  ChannelStatisticsResponse,
  ChannelCouponsStatisticsResponse,
  ChannelCouponStatisticsResponse,
  GetChannelStatisticsRequestOptions,
  GetChannelCouponRequestOptions,
  ExportChannelCouponRequestOptions,
  ExportChannelCouponsStatisticsResponse,
  ExportDailyCampaignResponse,
  RefreshDailyCampaignStatisticsRequestOptions,
  GetChannelAgentWithdrawalStatisticsRequestOptions,
  ChannelAgentWithdrawalStatisticsResponse
} from './interfaces';

export function getRevenueDaily({ query }: GetAnalyticRequestOptions = {}): Promise<RevenueDailyResponse> {
  return request({
    url: '/analytics/revenue/daily',
    method: 'get',
    params: query
  });
}

export function getChannelTodayRecord({ query }: GetAnalyticRequestOptions = {}): Promise<AnalyticResponse> {
  return request({
    url: '/analytics/channel/todayRecord',
    method: 'get',
    params: query
  });
}

export function getChannelRecord({ query }: GetAnalyticRequestOptions = {}): Promise<DashboardChannelListResponse> {
  return request({
    url: '/analytics/channel/records',
    method: 'get',
    params: query
  });
}

export function getUserLineChartRecords({ query }: GetAnalyticRequestOptions = {}): Promise<AnalyticResponse> {
  return request({
    url: '/analytics/channel/userLineChart',
    method: 'get',
    params: query
  });
}

export function getRevenueLineChartRecords({ query }: GetAnalyticRequestOptions = {}): Promise<AnalyticResponse> {
  return request({
    url: '/analytics/channel/revenueLineChart',
    method: 'get',
    params: query
  });
}

export function getUserPieChartRecords({ query }: GetAnalyticRequestOptions = {}): Promise<AnalyticResponse> {
  return request({
    url: '/analytics/channel/userPieChart',
    method: 'get',
    params: query
  });
}

export function getRevenuePieChartRecords({ query }: GetAnalyticRequestOptions = {}): Promise<AnalyticResponse> {
  return request({
    url: '/analytics/channel/revenuePieChart',
    method: 'get',
    params: query
  });
}

// Channel Analytics

export function getDailyCampaignStatistics({ query }: GetDailyCampaignStatisticsRequestOptions): Promise<DailyCampaignStatisticsResponse> {
  return request({
    url: '/analytics/campaigns/daily',
    method: 'get',
    params: query
  });
}

export function refreshDailyCampaignStatistics({ data }: RefreshDailyCampaignStatisticsRequestOptions): Promise<DailyCampaignStatisticsResponse> {
  return request({
    url: '/analytics/campaigns/daily',
    method: 'put',
    data
  });
}

export function exportDailyCampaign({ query }: GetDailyCampaignStatisticsRequestOptions): Promise<ExportDailyCampaignResponse> {
  return request({
    url: '/analytics/campaigns/daily/export',
    method: 'get',
    params: query,
    responseType: 'blob'
  });
}

export function getChannelStatistics({ query }: GetChannelStatisticsRequestOptions): Promise<ChannelStatisticsResponse> {
  return request({
    url: '/analytics/channels',
    method: 'get',
    params: query
  });
}

// 取得渠道體驗券統計資料
export function getChannelCouponsStatistics({ query }: GetChannelCouponRequestOptions): Promise<ChannelCouponsStatisticsResponse> {
  return request({
    url: '/analytics/channels/coupons',
    method: 'get',
    params: query
  });
}

// 匯出體驗券統計資料
export function exportChannelCouponsStatistics({ query }: ExportChannelCouponRequestOptions): Promise<ExportChannelCouponsStatisticsResponse> {
  return request({
    url: '/analytics/channels/coupons/export',
    method: 'get',
    params: query,
    responseType: 'blob'
  });
}

// 取得特地渠道特定體驗券每日統計資料
export function exportChannelCouponStatistics(ids: {
  channelId: number
  couponId: number
}, { query }: GetChannelCouponRequestOptions): Promise<ExportChannelCouponsStatisticsResponse> {
  return request({
    url: `/analytics/channels/${ids.channelId}/coupons/${ids.couponId}/export`,
    method: 'get',
    params: query,
    responseType: 'blob'
  });
}

// 取得特地渠道特定體驗券每日統計資料
export function getChannelCouponStatistics(ids: {
  channelId: number
  couponId: number
}, { query }: GetChannelCouponRequestOptions): Promise<ChannelCouponStatisticsResponse> {
  return request({
    url: `/analytics/channels/${ids.channelId}/coupons/${ids.couponId}`,
    method: 'get',
    params: query
  });
}

export function getChannelAgentWithdrawalStatistics({ query }: GetChannelAgentWithdrawalStatisticsRequestOptions): Promise<ChannelAgentWithdrawalStatisticsResponse> {
  return request({
    url: '/channel-agents/withdrawal-statistics',
    method: 'get',
    params: query
  });
}
