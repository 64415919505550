import { request } from './utils';

import {
  GenreResponse,
  GenresResponse,
  GetGenreRequestOptions,
  GetGenresRequestOptions,
  CreateGenreRequestOptions,
  UpdateGenreRequestOptions,
  DeleteGenreRequestOptions,
  SucceededResponse
} from './interfaces';

export function getGenre({ genreId }: GetGenreRequestOptions): Promise<GenreResponse> {
  return request({
    url: `/genres/${genreId}`,
    method: 'get'
  });
}

export function getGenres({ query }: GetGenresRequestOptions = {}): Promise<GenresResponse> {
  return request({
    url: '/genres',
    method: 'get',
    params: query
  });
}

export function createGenre({ data }: CreateGenreRequestOptions): Promise<GenreResponse> {
  return request({
    url: '/genres',
    method: 'post',
    data
  });
}

export function updateGenre({ genreId, data }: UpdateGenreRequestOptions): Promise<GenreResponse> {
  return request({
    url: `/genres/${genreId}`,
    method: 'put',
    data
  });
}

export function deleteGenre({ genreId }: DeleteGenreRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/genres/${genreId}`,
    method: 'delete'
  });
}

// export function resetQRCodeGenre({ genreId }: DeleteGenreRequestOptions): Promise<SucceededResponse> {
//   return request({
//     url: `/genres/${genreId}/resetQRCode`,
//     method: 'post'
//   });
// }

// export function enableGenre({ genreId }: EnableGenreRequestOptions): Promise<EnableResponse> {
//   return request({
//     url: `/genres/${genreId}/enable`,
//     method: 'post'
//   });
// }

// export function disableGenre({ genreId }: DisableGenreRequestOptions): Promise<DisableResponse> {
//   return request({
//     url: `/genres/${genreId}/disable`,
//     method: 'post'
//   });
// }
