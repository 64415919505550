import { request } from './utils';

import {
  GetWorkRequestOptions,
  GetWorksRequestOptions,
  CreateWorkRequestOptions,
  UpdateWorkRequestOptions,
  WorksResponse,
  WorkResponse,
  DeleteWorkRequestOptions,
  TakeWorkDownRequestOptions,
  SucceededResponse,
  PublishWorkRequestOptions
} from './interfaces';

export function getWork({
  workId
}: GetWorkRequestOptions): Promise<WorkResponse> {
  return request({
    url: `/works/${workId}`,
    method: 'get'
  });
}

export function getWorks({
  query
}: GetWorksRequestOptions): Promise<WorksResponse> {
  return request({
    url: '/works',
    method: 'get',
    params: query
  });
}

export function createWork({
  data
}: CreateWorkRequestOptions): Promise<SucceededResponse> {
  return request({
    url: '/works',
    method: 'post',
    data
  });
}

export function updateWork({
  workId,
  data
}: UpdateWorkRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/works/${workId}`,
    method: 'put',
    data
  });
}

export function deleteWork({
  workId
}: DeleteWorkRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/works/${workId}`,
    method: 'delete'
  });
}

export function takeWorkDown({
  workId
}: TakeWorkDownRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/works/${workId}/taken-down`,
    method: 'post'
  });
}

export function publishWork({
  workId
}: PublishWorkRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/works/${workId}/published`,
    method: 'post'
  });
}
