import { request } from './utils';

import {
  ActorResponse,
  ActorsResponse,
  GetActorRequestOptions,
  GetActorsRequestOptions,
  CreateActorRequestOptions,
  UpdateActorRequestOptions,
  DeleteActorRequestOptions,
  SucceededResponse
} from './interfaces';

export function getActor({ actorId }: GetActorRequestOptions): Promise<ActorResponse> {
  return request({
    url: `/actors/${actorId}`,
    method: 'get'
  });
}

export function getActors({ query }: GetActorsRequestOptions = {}): Promise<ActorsResponse> {
  return request({
    url: '/actors',
    method: 'get',
    params: query
  });
}

export function createActor({ data }: CreateActorRequestOptions): Promise<ActorResponse> {
  return request({
    url: '/actors',
    method: 'post',
    data
  });
}

export function updateActor({ actorId, data }: UpdateActorRequestOptions): Promise<ActorResponse> {
  return request({
    url: `/actors/${actorId}`,
    method: 'put',
    data
  });
}

export function deleteActor({ actorId }: DeleteActorRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/actors/${actorId}`,
    method: 'delete'
  });
}
