import { AxiosError } from 'axios';
import {
  PartialAdvertisement,
  SubscriptionSource,
  ChannelStatistics,
  ChannelCouponsStatistics,
  ChannelCouponStatistics,
  PartialChannel,
  Classification,
  PartialCoupon,
  Advertisement,
  DailyCampaign,
  Subscription,
  PartialTopic,
  PartialPhoto,
  PartialGenre,
  PartialActor,
  CampaignType,
  PartialPlan,
  PartialUser,
  OrderStatus,
  AdminStatus,
  FeatureWork,
  PartialRole,
  Permission,
  PartialTag,
  WorkStatus,
  TopicType,
  Campaign,
  Channel,
  Coupon,
  Block,
  Genre,
  Actor,
  Topic,
  Agent,
  Photo,
  Order,
  Admin,
  Work,
  Role,
  Plan,
  User,
  Tag,
  Director,
  PartialDirector,
  VerifiedType,
  UserReview,
  PartialVerityUserReview,
  VerifiedStatus,
  CampaignDaily,
  WteEvent,
  PartialWteEvent,
  RankingPrizeStatus,
  EventsSuggestion,
  OperationLog,
  OperationResource,
  PartialSuggestions,
  Refund,
  AnnouncementType,
  TargetUserType,
  AnnouncementStatus,
  CreateAnnouncementData,
  Announcement,
  AnnouncementSpecifiedUser,
  HotKeyword,
  PartialHotKeyword,
  RecommendKeyword,
  KeywordStatistics,
  HotKeywordStatistics,
  RecommendedKeywordStatistics,
  PartialChannelAgent,
  OrganicKeywordStatistics,
  WorkStatistics,
  PartialCampaignCommission,
  CampaignCommission,
  ChannelAgent,
  ChannelAgentWithdrawalStatus,
  ChannelAgentWithdrawal,
  ChannelAgentWithdrawalStatistics,
  RegisterMethod,
  Language,
  ShowExposes,
  PartialShowExposes,
  Advertisements,
  AdvertisementStatusType,
  AdvertisementPositionType,
  AdvertisementsStatistics,
  Event,
  EventsStatistics,
  EventsEnrollments,
  PartialEvent,
  TLinkType,
  EventsStatusType,
  SubscriptionCancellations
} from '@/interfaces';
import { number } from '@intlify/core-base';

export interface RequestOptions<TQuery = any, TData = any> {
  query?: TQuery
  data?: TData
}

export interface Response<TData = any> {
  data?: TData
  meta?: {
    currentPage: number
    lastPage: number
    pageSize: number
    total: number
  }
}

export interface ErrorData {
  error?: {
    code: number
    message: string
    traceCode: string
  }
  fieldErrors?: Array<{
    field: string
    message: {
      en: string
      zh: string
    }
  }>
  errorCode?: number
  message?: string
}

export type ResponseError = Partial<AxiosError<ErrorData>>;
export type SucceededResponse = Response;

export interface BaseQuery {
  page?: number
  pageSize?: number
}

export enum IntBoolean {
  TRUE = 1,
  FALSE = 0
}

export enum SortOrder {
  ASCENDING = 'asc',
  DESCENDING = 'desc'
}

export enum ShowExposeSortKey {
  ID = 'id',
  CREATEDAT = 'createdAt',
  UPDATEDAT = 'updatedAt',
}

export enum ScheduleStatus {
  PREPARED = 'prepared',
  ACTIVED = 'actived',
  EXPIRED = 'expired'
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female'
}

export type StringNumber = string
export type StringOrNumber = string | number

// login auth request
export interface AuthRequestData {
  username: string
  password: string
}
export type LoginRequestData = AuthRequestData
export type LoginRequestOptions = RequestOptions<any, LoginRequestData>

// login auth response
export type LoginResponse = Response<{
  username: string
  qrCode: string
  secret: string
}>

// TOTP login auth request
export interface TOTPLoginAuthRequestData {
  secret: string
  code: string
  username: string
}
export type TOTPLoginRequestData = TOTPLoginAuthRequestData
export type TOTPLoginRequestOptions = RequestOptions<any, TOTPLoginRequestData>

// TOTP login auth response
export type TOTPLoginResponse = Response<{
  id: number
  username: string
  name: string
  phone: string
  status: AdminStatus
  roles: Role[]
  token: string
  createdAt: string
}>

// Logout
export type LogoutResponse = SucceededResponse

// Work
interface WorksQuery extends BaseQuery {
  isSeries?: IntBoolean
  keyword?: string
  with?: string
  noExpose?: IntBoolean
  agentId?: number
  genreId?: number
  tagId?: number
  status?: string
  topTopicId?: number
  classificationIds?: number[]
  isChoose?: number
  lang?: string
}

export type WorkIdOption = { workId: string | number };

interface MultiLangName {
  'zh-TW': string
  'en-US': string
}

export interface CreateWorkData {
  agentId: number
  name: MultiLangName
  number: string
  coverPhotoId: number
  mobileCoverPhotoId?: number
  captionCoverPhotoId: number
  titlePhotoId: number
  video: string
  videoSource: string
  trailer: string
  trailerSource: string
  noExposeTrailer: string
  noExposeTrailerSource: string
  isExclusive: boolean
  classificationIds: number[]
  genreIds: number[]
  actorIds: number[]
  directorIds: number[]
  tagIds: number[]
  introduction: MultiLangName
  publishedAt: string
  copyrightExpiredAt: string
  status: WorkStatus
  noExposeCoverPhotoId: number
  noExposeMobileCoverPhotoId: number
  noExposeCaptionCoverPhotoId: number
}

export type GetWorksRequestOptions = RequestOptions<WorksQuery>;
export type GetWorkRequestOptions = RequestOptions & WorkIdOption;
export type CreateWorkRequestOptions = RequestOptions<any, CreateWorkData>;
export type UpdateWorkRequestOptions = CreateWorkRequestOptions & WorkIdOption;
export type DeleteWorkRequestOptions = WorkIdOption;
export type TakeWorkDownRequestOptions = WorkIdOption;
export type PublishWorkRequestOptions = WorkIdOption;

export type WorkResponse = Response<Work>;
export type WorksResponse = Response<Work[]>;

// FeatureWork
export interface FeatureWorksQuery extends BaseQuery {
  blockId?: string
}

export type GetFeatureWorksRequestOptions = RequestOptions<FeatureWorksQuery>;
export type UpdateBlockFeatureWorkRequestOptions = RequestOptions<FeatureWorksQuery, WorkIdOption>;

export type FeatureWorksResponse = Response<FeatureWork[]>;

// Classification
export type GetClassificationsRequestOptions = RequestOptions<BaseQuery>;
export type ClassificationsResponse = Response<Classification[]>;

// Agent
export interface AgentsQuery extends BaseQuery {
  keyword?: string
}
export interface UpdateAgentsQuery {
  id: string | number
}
export interface AgentsData {
  name: string
}
export type GetAgentsRequestOptions = RequestOptions<AgentsQuery>;
export type CreateAgentRequestOptions = RequestOptions<any, AgentsData>
export type UpdateAgentRequestOptions = RequestOptions<UpdateAgentsQuery, AgentsData>
export type AgentsResponse = Response<Agent[]>;

// Actor
export interface ActorsQuery extends BaseQuery {
  lang?: string
};
export type ActorIdOption = { actorId: string };
export type GetActorRequestOptions = RequestOptions & ActorIdOption;
export type GetActorsRequestOptions = RequestOptions<ActorsQuery>;
export type CreateActorRequestOptions = RequestOptions<any, PartialActor>;
export type UpdateActorRequestOptions = CreateActorRequestOptions & { actorId: string }
export type DeleteActorRequestOptions = ActorIdOption;

export type ActorResponse = Response<Actor>;
export type ActorsResponse = Response<Actor[]>;

// Genre
export type GenreIdOption = { genreId: string };
export interface GenresQuery extends BaseQuery {
  keyword?: string
  lang?: string
}
export type GetGenresRequestOptions = RequestOptions<GenresQuery>;
export type GetGenreRequestOptions = RequestOptions & GenreIdOption
export type CreateGenreRequestOptions = RequestOptions<any, PartialGenre>
export type UpdateGenreRequestOptions = CreateGenreRequestOptions & GenreIdOption
export type GenresResponse = Response<Genre[]>;
export type GenreResponse = Response<Genre>;
export type DeleteGenreRequestOptions = GenreIdOption;

// Tag
export interface TagsQuery extends BaseQuery {
  lang?: string
};
export type TagIdOption = { tagId: string };
export type GetTagRequestOptions = RequestOptions & TagIdOption;
export type GetTagsRequestOptions = RequestOptions<TagsQuery>;
export type CreateTagRequestOptions = RequestOptions<any, PartialTag>;
export type UpdateTagRequestOptions = CreateTagRequestOptions & TagIdOption;
export type DeleteTagRequestOptions = TagIdOption;
export type TagResponse = Response<Tag>;
export type TagsResponse = Response<Tag[]>;

// User
export enum UserSortKey {
  ID = 'id',
  CREATED_AT = 'createdAt',
  SUBSCRIPTION_ENDED_AT = 'subscriptionEndedAt'
}

export interface UsersQuery extends BaseQuery {
  isNFTMember?: IntBoolean
  isBlack?: IntBoolean
  isContactMailVerified?: IntBoolean
  registerStartAt?: string
  registerEndAt?: string
  sort?: SortOrder
  sortKey?: UserSortKey
  verifiedStatus?: VerifiedStatus
  keyword?: string
  registerMethod?: RegisterMethod
}

interface UsersReviewQuery extends BaseQuery {
  verifiedType?: VerifiedType
  keyword?: string
}

export type UserIdOption = { userId: string };
export type GetUserRequestOptions = RequestOptions & { userId: string };
export type GetUsersRequestOptions = RequestOptions<UsersQuery>;
export type CreateUserRequestOptions = RequestOptions<any, PartialUser>;
export type UpdateUserRequestOptions = CreateUserRequestOptions & { userId: string };
export type DeleteUserRequestOptions = UserIdOption;

export interface ExportUsersQuery extends UsersQuery {
  exportColumns?: string[]
}

export type ExportUsersRequestOptions = RequestOptions<ExportUsersQuery>;
export type ExportUsersResponse = Response<BinaryType>;

export type UserResponse = Response<User>;
export type UsersResponse = Response<User[]>;
export type GetWatchHistories = RequestOptions<{ memberNo?: string }>

// UserReview
export type GetUsersReviewRequestOptions = RequestOptions<UsersReviewQuery>;
export type UpdateUserReviewRequestOptions = { userId: string } & RequestOptions<any, PartialVerityUserReview>;

export type UsersReviewResponse = Response<UserReview[]>;
// Plan
interface PlansQuery extends BaseQuery {
  keyword?: string
}
export type PlanIdOption = { planId: string };

export type GetPlanRequestOptions = RequestOptions & PlanIdOption;
export type GetPlansRequestOptions = RequestOptions<PlansQuery>;
export type CreatePlanRequestOptions = RequestOptions<any, PartialPlan>;
export type UpdatePlanRequestOptions = CreatePlanRequestOptions & PlanIdOption;
export type PlanResponse = Response<Plan>;
export type PlansResponse = Response<Plan[]>;

// Operation
interface OperationLogsQuery extends BaseQuery {
  startDate?: string
  endDate?: string
  keyword?: string
  resource?: string
  action?: string
}
export type GetOperationLogsRequestOptions = RequestOptions<OperationLogsQuery>;
export type OperationLogsResponse = Response<OperationLog[]>;

export type GetOperationResourcesRequestOptions = RequestOptions<BaseQuery>;
export type OperationResourcesResponse = Response<OperationResource[]>;

// show-exposes
interface ShowExposesQuery extends BaseQuery {
  status?: string
  sort?: string
  sortKey?: string
}
export type ShowExposesOption = { id: string };
export type GetShowExposesRequestOptions = RequestOptions<ShowExposesQuery>;
export type CreateShowExposesRequestOptions = RequestOptions<any, PartialShowExposes>;
export type UpdateShowExposesRequestOptions = CreateShowExposesRequestOptions & ShowExposesOption;
export type ShowExposesResponse = Response<ShowExposes[]>;
export type ShowExposeResponse = Response<ShowExposes>;

// Coupon
interface CouponsQuery extends BaseQuery {
  keyword?: string
}
export type CouponIdOption = { couponId: string };

export type GetCouponRequestOptions = RequestOptions & CouponIdOption;
export type GetCouponsRequestOptions = RequestOptions<CouponsQuery>;
export type CreateCouponRequestOptions = RequestOptions<any, PartialCoupon>;
export type UpdateCouponRequestOptions = CreateCouponRequestOptions & CouponIdOption;
export type DeleteCouponRequestOptions = CouponIdOption;
export type TakeCouponDownRequestOptions = CouponIdOption;
export type CouponResponse = Response<Coupon>;
export type CouponsResponse = Response<Coupon[]>;

// Admin
interface AdminsQuery extends BaseQuery {
  keyword?: string
}

export type AdminIdOption = { adminId: string };
export type GetAdminRequestOptions = RequestOptions & AdminIdOption;
export type GetAdminsRequestOptions = RequestOptions<AdminsQuery>;

export interface CreateAdminData {
  username: string
  password: string
  name: string
  phone: string
  status: AdminStatus
  roleIds: number[]
}

export type CreateAdminRequestOptions = RequestOptions<any, CreateAdminData>;
export type UpdateAdminRequestOptions = CreateAdminRequestOptions & AdminIdOption;
export type DeleteAdminRequestOptions = AdminIdOption;
export type ResetAdmin2FARequestOptions = AdminIdOption;
export type EnableAdminRequestOptions = AdminIdOption;
export type DisableAdminRequestOptions = AdminIdOption;

export type AdminResponse = Response<Admin>;
export type AdminsResponse = Response<Admin[]>;

// Role
type RolesQuery = BaseQuery;

export type RoleIdOption = { roleId: string };

export type GetRoleRequestOptions = RequestOptions & RoleIdOption;
export type GetRolesRequestOptions = RequestOptions<RolesQuery>;
export type CreateRoleRequestOptions = RequestOptions<any, PartialRole>;
export type UpdateRoleRequestOptions = CreateRoleRequestOptions & RoleIdOption;
export type DeleteRoleRequestOptions = RoleIdOption;

export type RoleResponse = Response<Role>;
export type RolesResponse = Response<Role[]>;

// Permissions
export type PermissionsResponse = Response<Permission>;

// Order
export enum OrderSortKey {
  ID = 'id',
}

export type OrderIdOption = { orderId: string };
export interface OrdersQuery extends BaseQuery {
  createdStartAt?: string
  createdEndAt?: string
  status?: OrderStatus
  planId?: number
  keyword?: string
  sort?: SortOrder
  sortKey?: OrderSortKey
  paymentGateway?: string
}

export interface RefundQuery {
  refundType: RefundType
}

export type GetOrdersRequestOptions = RequestOptions<OrdersQuery>;
export type GetOrderRequestOptions = RequestOptions & OrderIdOption;
export type OrdersResponse = Response<Order[]>;
export type OrderResponse = Response<Order>;
export type GetRefundRequestOptions = RequestOptions<RefundQuery> & OrderIdOption
export type RefundResponse = Response<Refund>;

// Subscription
interface SubscriptionsQuery extends BaseQuery {
  startDate?: string
  endDate?: string
  planId?: number
  source?: SubscriptionSource
  keyword?: string
}
interface SubscriptionCancellationsQuery extends BaseQuery {
  startedAt?: string
  endedAt?: string
  planId?: number
  cancelReason?: string
  keyword?: string
}

export type SubscriptionIdOption = { planId: string };
export type GetSubscriptionsRequestOptions = RequestOptions<SubscriptionsQuery>;
export type GetSubscriptionCancellationsRequestOptions = RequestOptions<SubscriptionCancellationsQuery>;
export type SubscriptionsResponse = Response<Subscription[]>;
export type SubscriptionCancellationsResponse = Response<SubscriptionCancellations[]>;
export type SubscriptionCancellationOptions = RequestOptions<any, { cancelReason: string }> & IdOption;

type BlocksQuery = BaseQuery
interface BlockTopicData {
  topics: { id: number, isPopular?: boolean }[]
}
interface BlockFeatureWorkData {
  works: { id: number }[]
}
export type BlockIdOption = { blockId: number | string }

export type GetBlocksRequestOptions = RequestOptions<BlocksQuery>
export type BlockTopicOptions = RequestOptions<any, BlockTopicData> & BlockIdOption
export type BlockFeatureWorkOptions = RequestOptions<any, BlockFeatureWorkData> & BlockIdOption
export type BlocksResponse = Response<Block[]>;
export type BlockResponse = Response<Block>;

type PhotoQuery = BaseQuery

export type SortType = 'asc' | 'desc'

// Advertisement
interface AdvertisementsQuery extends BaseQuery {
  keyword?: string
  status?: AdvertisementStatusType
  position?: AdvertisementPositionType
  blockIds?: number[]
  sort?: SortType
}

interface AdvertisementsStatisticsQuery extends AdvertisementsQuery {
  startedAt?: string
  endedAt?: string
}

export type AdvertisementIdOption = { id: number }
export type GetAdvertisementRequestOptions = RequestOptions & AdvertisementIdOption;
export type GetAdvertisementsRequestOptions = RequestOptions<AdvertisementsQuery>;
export type GetAdvertisementsStatisticsRequestOptions = RequestOptions<AdvertisementsStatisticsQuery>;
export type AdvertisementResponse = Response<Advertisement & {
  blocks: Block[]
}>;
export type AdvertisementsResponse = Response<Advertisements[]>;
export type AdvertisementsStatisticsResponse = Response<AdvertisementsStatistics[]>;

// export type MutateAdvertisementData = Omit<PartialAdvertisement, 'coverPhoto'> & { coverPhoto?: File | Photo }
export type AdvertisementData = PartialAdvertisement;
export type CreateAdvertisementRequestOptions = RequestOptions<any, AdvertisementData>;
export type UpdateAdvertisementRequestOptions = RequestOptions<any, AdvertisementData> & AdvertisementIdOption;
export type DeleteAdvertisementRequestOptions = AdvertisementIdOption;

// Topic
interface TopicsQuery extends BaseQuery {
  blockId?: number
  type?: TopicType
  keyword?: string
  classificationIds?: number[]
  blacklistIds?: number[]
  lang?: string
}
export type TopicIdOption = { topicId: string }
export type GetTopicRequestOptions = RequestOptions & TopicIdOption;
export type GetTopicsRequestOptions = RequestOptions<TopicsQuery>;
export type TopicResponse = Response<Topic>;
export type TopicsResponse = Response<Topic[]>;

export type MutateTopicData = PartialTopic & {
  classificationIds: number[]
  blockIds: number[]
  workIds: number[]
  thumbnailPhoto?: Photo
  bannerPhoto?: Photo
  thumbnailPhotoId: number
  bannerPhotoId: number
  borderPhotoId: number
}
interface TopicWorksQuery extends WorksQuery {
  topicId?: number
}

export type CreateTopicRequestOptions = RequestOptions<any, MutateTopicData>;
export type UpdateTopicRequestOptions = RequestOptions<any, MutateTopicData> & TopicIdOption;
export type DeleteTopicRequestOptions = TopicIdOption;
export type CreateTopicWorkRequestOptions = RequestOptions & TopicIdOption & WorkIdOption;
export type DeleteTopicWorkRequestOptions = TopicIdOption & WorkIdOption;
export type GetTopicWorksRequestOptions = RequestOptions<TopicWorksQuery>;

export type PhotoIdOption = { photoId: string };
export type GetPhotoRequestOptions = RequestOptions & { photoId: string };
export type GetPhotosRequestOptions = RequestOptions<PhotoQuery>;
export type CreatePhotoRequestOptions = RequestOptions<any, PartialPhoto>;
export type UpdatePhotoRequestOptions = CreatePhotoRequestOptions & { photoId: string };
export type DeletePhotoRequestOptions = PhotoIdOption;
export type PhotoResponse = Response<Photo>;
export type PhotosResponse = Response<Photo[]>;

export enum VideoType {
  TRAILER = 'trailers',
  VIDEO = 'videos'
}

// Media
export type CreatePresignedUrlsRequestOptions = RequestOptions<any, {
  parts: number
  filetype: string
  type: VideoType
}>

export type CreatePresignedUrlsResponse = Response<{
  filename: string
  urls: string[]
  uploadId: string
}>;

export type CompleteMultipartUploadRequestOptions = RequestOptions<any, {
  uploadId: string
  filename: string
  etags: string[]
}>;

export type CompleteMultipartUploadResponse = Response<{
  bucket: string
  eTag: string
  key: string
  location: string
  signedUrl: string
}>;

export type CreateConvertMediaJobRequestOptions = RequestOptions<any, {
  filename: string
}>;

export type CreateConvertMediaJobResponse = Response<void>;

export type UploadPhotoRequestOptions = RequestOptions<any, {
  photos: Blob
}>;

export type UploadPhotoResponse = Response<Photo[]>;

// File
interface ImageData {
  image: File
  image_meta?: string
  type: string
}

export type UploadImageRequestOptions = RequestOptions<any, ImageData>
export interface ImageResponseData {
  path: string
  link: string
}

export type ImageResponse = Response<ImageResponseData>;

interface VideoData {
  video: File
}

export type UploadVideoRequestOptions = RequestOptions<any, VideoData>

export interface VideoResponseData {
  path: string
}

export type UploadVideoResponse = Response<VideoResponseData>;

interface FileData {
  /** 原始檔名  */
  filename: string
  /** 類型 */
  type: string
  /** 分塊號 */
  block_id: number
  /** 分塊總數 */
  block_tot: number
  /** 分塊檔 */
  block: Blob
  /** 檔案所在目錄 */
  dir: string
  /** 原始檔名 */
  oriname: string
  /** 副檔名 */
  ext: string
  /** 起始檔案分塊大小 */
  start: number
  /* 存檔名 */
  uuid: string
}
export type UploadFileRequestOptions = RequestOptions<any, FileData>

export interface FileResponse {
  block_id: string
  block_tot: number // 全部上傳完成，返回 block_tot
  datetime: number
  uuid: string
  source?: string
  start: number
  path?: string
  time: number
  temp_file_count: number
}

export interface FileInfo {
  uuid: string
  dir: string
  ext: string
}

export interface CheckFileResponse {
  size: number
  source?: string
  path?: string
}

export type CheckFileOnCloudRequestOptions = RequestOptions<FileInfo>
export type CheckFileOnCloudResponse = CheckFileResponse

export type CheckFileOnServerRequestOptions = RequestOptions<FileInfo>
export type CheckFileOnServerResponse = CheckFileResponse

export type UploadToCloudRequestOptions = RequestOptions<any, FileInfo>
export type UploadToCloudResponse = CheckFileResponse & {
  error?: string
  uuid?: string
  time?: string
}

export type CheckChunkListRequestOptions = RequestOptions<FileInfo & {
  block_tot: number
}>
export interface CheckChunkListResponse {
  block_arr: number[]
}

// Director
export interface DirectorsQuery extends BaseQuery {
  lang?: string
};
export interface DirectorIdOption { directorId: string }
export type GetDirectorRequestOptions = RequestOptions & DirectorIdOption;
export type GetDirectorsRequestOptions = RequestOptions<DirectorsQuery>;
export type CreateDirectorRequestOptions = RequestOptions<any, PartialDirector>;
export type UpdateDirectorRequestOptions = CreateDirectorRequestOptions & DirectorIdOption;
export type DeleteDirectorRequestOptions = DirectorIdOption;

export type DirectorResponse = Response<Director>;
export type DirectorsResponse = Response<Director[]>;

// Channel
interface ChannelsQuery extends BaseQuery {
  keyword?: string
  channelAgentId?: string
}
export type ChannelIdOption = { channelId: string };
export type GetChannelRequestOptions = RequestOptions & ChannelIdOption;
export type GetChannelsRequestOptions = RequestOptions<ChannelsQuery>;
export type GetChannelsAgentsRequestOptions = RequestOptions<ChannelsQuery>;
export type CreateChannelRequestOptions = RequestOptions<any, PartialChannel>;
export type UpdateChannelRequestOptions = CreateChannelRequestOptions & ChannelIdOption;
export type DeleteChannelRequestOptions = ChannelIdOption;
export type ChannelResponse = Response<Channel>;
export type ChannelsResponse = Response<Channel[]>;

// Campaign
interface CampaignsQuery extends BaseQuery {
  channelId?: number
  utmMedium?: CampaignType
  keyword?: string
  channelAgentId?: string
  sortKey?: string
  sort?: string
}

export interface CreateCampaignData {
  channelId: number
  utmFormat: string
  utmCampaign: string
  utmSize: string
  amount: StringNumber
  startedAt: string
  endedAt: string
}

export type PartialCampaignData = Partial<CreateCampaignData>

export interface CalibrateCampaignData {
  date?: string
  originalAmount?: string
  actualAmount?: string
  endedAt?: string
}

export interface CpaRuleData {
  type: string
  amount: number
}

export interface CpaOverviewData {
  registerCountTotal: number
  registerAmountTotal: number
  averageCPARegister: number
  subscriptionCountTotal: number
  subscriptionAmountTotal: number
  averageCPASubscription: number
  countTotal: number
  amountTotal: number
  averageCPA: number
}

export interface GetCpaStatisticsQuery extends BaseQuery {
  startedAt: string
  endedAt: string
}

export interface ExportCpaQuery {
  startedAt: string
  endedAt: string
}

export interface UpdateCpaStatisticsData {
  date: string
  registerActualCount: number
  validateActualCount: number
}

export interface GetCpaStatisticsResponseData {
  date: string
  registerOriginalCount: number
  registerActualCount: number
  registerAmount: number
  registerTotal: number
  validateOriginalCount: number
  validateActualCount: number
  validateAmount: number
  validateTotal: number
  countTotal: number
  amountTotal: number
}

export type CampaignIdOption = { campaignId: string };

export type GetCampaignRequestOptions = RequestOptions & CampaignIdOption;
export type GetCampaignsRequestOptions = RequestOptions<CampaignsQuery>;
export type CreateCampaignRequestOptions = RequestOptions<any, CreateCampaignData>;
export type UpdateCampaignRequestOptions = RequestOptions<any, PartialCampaignData> & CampaignIdOption;
export type DeleteCampaignRequestOptions = CampaignIdOption;
export type CalibrateCampaignRequestOptions = RequestOptions<any, CalibrateCampaignData>;
export type CreateCpaRuleRequestOptions = RequestOptions<any, CpaRuleData[]>
export type GetCpaStatisticsOptions = RequestOptions<GetCpaStatisticsQuery, any>
export type UpdateCpaStatisticsOptions = RequestOptions<any, UpdateCpaStatisticsData[]>
export type ExportCpaOptions = RequestOptions<ExportCpaQuery, any>

export type CampaignResponse = Response<Campaign>;
export type CampaignsResponse = Response<Campaign[]>;
export type CampaignsDailyResponse = Response<CampaignDaily>;
export type GetCpaStatisticsResponse = Response<GetCpaStatisticsResponseData[]>;
export type CpaOverviewResponse = Response<CpaOverviewData>;

interface EventsSuggestionQuery extends BaseQuery {
  eventId?: number | string
  eventStartedAt?: string
  eventEndedAt?: string
  status? : string
  keyword?: string
}
interface SuggestionsQuery extends EventsSuggestionQuery {
  type?: string
}

// suggestion
export type EventIdOption = { eventId: string };
export type SuggestionIdOption = { suggestionId: string };
export type GetEventsSuggestionRequestOptions = RequestOptions<EventsSuggestionQuery>;
export type GetSuggestionsRequestOptions = RequestOptions<SuggestionsQuery>;
export type GetSuggestionCountRequestOptions = GetEventsSuggestionRequestOptions & EventIdOption;
export type CreateSuggestionRequestOptions = EventIdOption & RequestOptions<any, PartialSuggestions>;
export type EventsSuggestionResponse = Response<EventsSuggestion[]>;
export type SuggestionsResponse = Response<PartialSuggestions[]>;
export type SuggestionsCountResponse = Response<EventsSuggestion>;
export type ChangeStatusRequestOptions = SuggestionIdOption;
export type ExcludeAllRequestOptions = RequestOptions<{ suggestionIds: number[] }>

// Channel Analytics
export enum DailyCampaignSortKey {
  CREATED_AT = 'createdAt',
  DATE = 'date',
  CAMPAIGN_COST = 'campaignCost',
  UNIQUE_IP_COUNT = 'uniqueIpCount',
  NEXT_DAY_RETENTION_COUNT = 'nextDayRetentionCount',
  THREE_DAY_RETENTION_COUNT = 'threeDayRetentionCount',
  WEEK_RETENTION_COUNT = 'weekRetentionCount',
  REGISTRATION_COUNT = 'registrationCount',
  SUBSCRIPTION_COUNT = 'subscriptionCount',
  SUBSCRIPTION_AMOUNT = 'subscriptionAmount',
  RENEWAL_COUNT_TO_DATE = 'renewalCountToDate',
  RENEWAL_AMOUNT_TO_DATE = 'renewalAmountToDate',
  AVERAGE_RENEWAL_AMOUNT_TO_DATE = 'averageRenewalAmountToDate',
  RENEWAL_RATIO_TO_DATE = 'RenewalRatioToDate',
  PROFIT_RATIO_TO_DATE = 'profitRatioToDate',
  ANDROID_BROWSER_VISIT_COUNT = 'androidBrowserVisitCount',
  ANDROID_APP_VISIT_COUNT = 'androidAppVisitCount',
  IOS_BROWSER_VISIT_COUNT = 'iosBrowserVisitCount',
  IOS_APP_VISIT_COUNT = 'iosAppVisitCount',
  PC_BROWSER_VISIT_COUNT = 'pcBrowserVisitCount',
  COST_PER_CLICK_TO_DATE = 'costPerClickToDate',
  COST_PER_ACTION_TO_DATE = 'costPerActionToDate',
  COST_PER_SALES_TO_DATE = 'costPerSalesToDate'
}

export interface DailyCampaignsQuery extends BaseQuery {
  startDate?: string
  endDate?: string
  channelIds?: number[]
  sortKey?: string
  sort?: SortOrder
  keyword?: string
}

export interface ChannelStatisticsQuery {
  startDate?: string
  endDate?: string
  channelIds?: number[]
  keyword?: string
}

export interface ChannelCouponStatisticsQuery extends BaseQuery {
  startDate?: string
  endDate?: string
  sort?: SortOrder
  sortKey?: string | number
}

export interface ExportChannelCouponStatisticsQuery {
  startDate?: string
  endDate?: string
  sort?: SortOrder
  sortKey?: string | number
}

export type GetDailyCampaignStatisticsRequestOptions = RequestOptions<DailyCampaignsQuery>;
export type RefreshDailyCampaignStatisticsRequestOptions = RequestOptions<any, {
  startDate: string
  endDate: string
}>;
export type GetChannelStatisticsRequestOptions = RequestOptions<ChannelStatisticsQuery>;
export type GetChannelCouponRequestOptions = RequestOptions<ChannelCouponStatisticsQuery>;
export type ExportChannelCouponRequestOptions = RequestOptions<ExportChannelCouponStatisticsQuery>;

export type DailyCampaignStatisticsResponse = Response<DailyCampaign[]>;
export type ExportDailyCampaignResponse = Response<BinaryType>;
export type ChannelStatisticsResponse = Response<ChannelStatistics>;
export type ChannelCouponsStatisticsResponse = Response<ChannelCouponsStatistics>;
export type ChannelCouponStatisticsResponse = Response<ChannelCouponStatistics>;
export type ExportChannelCouponsStatisticsResponse = Response<BinaryType>;

// Outdated
interface DailyDataQuery extends BaseQuery {
  date?: string
}

export interface AnalyticQuery extends BaseQuery {
  date?: string
  start_date?: string
  end_date?: string
}

// Get order success ratio
interface OrderSuccessRatioQuery{
  start_date?: string
  end_date?: string
}
export type GetOrderSuccessRatioRequestOptions = RequestOptions<OrderSuccessRatioQuery>
export type OrderSuccessRatioResponse = Response;

// Dashboard
interface DashboardQuery {
  page?: number
  start_date?: string
  end_date?: string
  type?: 'visitors' | 'subscribers'
}
export type GetDashboardRequestOptions = RequestOptions<DashboardQuery>;
export type DashboardResponse = Response;
interface Revenue{
  amount: number
  count: number
  atv: number
}
export type DashboardRevenueGatherResponse = Response<{
  new_revenue?: Revenue
  renew_revenue?: Revenue
  total_revenue?: Revenue
}>;
export type DashboardRevenueTrendResponse = Response<{
  date: string[]
  new_revenue: number[]
  renew_revenue: number[]
  total_revenue: number[]
}>;
export type DashboardMemberTrendResponse = Response<{
  date: string[]
  new_count: number[]
  renew_count: number[]
  total_count: number[]
}>;
export type DashboardChannelListResponse = Response<{
  arpu: number
  channel: PartialChannel
  channel_id: number
  revenue: number
  subscribers_count: number
  visitors_count: number
  returning_user_count: number
}[]>;

export type DashboardPlanListResponse = Response<{
  plan: PartialPlan
  plan_id: number
  revenue: number
  subscribers_count: number
}[]>;
export type DashboardDevicesResponse = Response<{
  name: string
  value: number
}[]>;
export type DashboardRealTimeUsersResponse = Response<{
  date: string[]
  value: {
    total_visitors_count: number[] // 總訪客
    channel_visitors_count: number[] // 渠道訪客
    direct_visitors_count: number[] // 自來量訪客
    total_subscribers_count: number[] // 總會員
    channel_subscribers_count: number[] // 渠道會員
    direct_subscribers_count: number[] // 自來量會員
  }
}>;
export type DashboardRealTimeRevenuesResponse = Response<{
  date: string[]
  value: {
    total_revenue: number[] // 總營收
    channel_revenue: number[] // 渠道營收
    direct_revenue: number[] // 自來量營收
  }
}>;

export type GetAnalyticRequestOptions = RequestOptions<AnalyticQuery>;
export type AnalyticResponse = Response;

export type GetDailyDataRequestOptions = RequestOptions<DailyDataQuery>;

interface Revenue {
  amount: number
  count: number
}
export type RevenueDailyResponse = Response<{
  todayRevenue?: Revenue
  totalRevenue?: Revenue
  intervalRevenue?: Revenue
  renewRevenue?: Revenue
  revenueList?: Response<{
    _id: string
    date: string
    new_revenue: number
    new_count: number
    renew_revenue: number
    renew_count: number
    refund_amount: number
    refund_count: number
    updated_at: string
    created_at: string
  }[]>
}>;

// wte
interface WteEventListQuery extends BaseQuery {
  eventStartedAt?: string
  eventEndedAt?: string
  status?: RankingPrizeStatus
  eventId?: string | number
}

export type GetWteEventListRequestOptions = RequestOptions<WteEventListQuery>;
export type GetWteEventListResponse = Response<WteEvent[]>;

export type GetWteEventRequestOptions = { eventId: string };
export type GetWteEventResponse = Response<WteEvent>;

export type UpdateWteEventRequestOptions = { eventId: string } & RequestOptions<any, PartialWteEvent>;
export type CreateWteEventRequestOptions = RequestOptions<any, PartialWteEvent>;

export type ShowEventUserStatisticsType = 'total_enrollment' | 'valid_enrollment' | 'total_vote' | 'valid_vote' | 'member_no';
export enum RankPrizeType {
  PRODUCT = 'product',
  CONTENT = 'content',
  ACTOR = 'actor'
}
export interface RankPrize {
  type: RankPrizeType
  prize: string
  rank: number
  memberNo: string
  isEligible: boolean
  suggestionId: number
}

export type GetEventStatisticsRequestOptions = RequestOptions<{
  page?: number
  pageSize?: number
  keyword?: string
  startDate?: string
  endDate?: string
}>

export interface EventStatistics {
  eventId: number
  eventStartedAt: string
  eventEndedAt: string
  totalEnrollmentCount: number
  validEnrollmentCount: number
  totalVotedEnrollmentCount: number
  validVotedEnrollmentCount: number
  totalSuggestionCount: number
  enrollPrize: number
  votePrize: number
  rankPrizes: RankPrize[]
  officialPrize: number
  enrollNftCount: number
  voteNftCount: number
  exchangeRate: number
  paymentAmount: number
}
export type GetEventStatisticsResponse = Response<EventStatistics[]>

export enum PrizeStatus {
  PAID = 'paid',
  PROCESSING = 'processing',
  INVALID = 'invalid',
  VALID = 'valid'
}

export type GetEventUserStatisticsRequestOptions = RequestOptions<{
  wteEventId?: number
  memberNo?: string
  userType?: ShowEventUserStatisticsType
  page?: number
  pageSize?: number
}>
export interface EventUserStatistics {
  eventId: number
  memberNo: string
  enrollPrize: number
  votePrize: number
  rankPrizes: RankPrize[]
  officialPrize: number
  enrollNftCount: number
  paymentAmount: number
  prizeStatus: PrizeStatus
}
export type GetEventUserStatisticsResponse = Response<EventUserStatistics[]>

export type ExportEventStatistics = RequestOptions<{
  startDate?: string
  endDate?: string
  keyword?: string
}>
export type ExportEventStatisticsResponse = Response<BinaryType>;

export type ExportEventDataDetail = RequestOptions<{
  eventId?: number
  userType?: ShowEventUserStatisticsType
  memberNo?: string
  keyword?: string
}>
export type ExportEventUserStatisticsResponse = Response<BinaryType>;

export type TransferStatus = 'processing' | 'success' | 'fail' | '';
export type VerifyStatus = 'verifying' | 'verified' | '';

export interface BaseWithdrawalQuery {
  keyword: string
  transferStatus: TransferStatus
  verifyStatus: VerifyStatus
  startedAt: string
  endedAt: string
}

export interface WithdrawalQuery extends BaseWithdrawalQuery, BaseQuery {}

export type GetWithdrawalOptions = RequestOptions<WithdrawalQuery>;
export type ExportWithdrawalOptions = RequestOptions<BaseWithdrawalQuery>;
export type ExportWithdrawalResponse = Response<BinaryType>;

export interface Withdrawal {
  id: number
  withdrawnAt: string
  user: {
    memberNo: string
  }
  walletAddress: string
  appliedAmount: string
  receivedAmount: string
  events: {
    id: number
    receivedAt: string
    receivedAmount: string
  }[]
  verifiedAt: string
  verifier: {
    name: string
  }
  transferStatus: TransferStatus
  verifyStatus: VerifyStatus
}
export interface WithdrawalResponse extends Response<Withdrawal[]> {
  statistics: WithdrawalStatistics
}
export type GetWithdrawalsResponse = WithdrawalResponse

export interface WithdrawalDetail {
  eventId: number
  eventEndedAt: string
  paymentAmount: string
}
export type GetWithdrawalDetailResponse = Response<WithdrawalDetail[]>;

export interface WithdrawalStatistics {
  totalWithdrawalCount: number
  totalWithdrawalAmount: string
  uniqueMemberCount: number
}

// #region Language

export type LanguagesResponse = Response<Language[]>;

// #endregion

// Announcement / front stage's Notification
export enum AnnouncementSortKey {
  ID = 'id'
};

interface AnnouncementsQuery extends BaseQuery {
  keyword?: string
  startedAt?: string
  endedAt?: string
  type?: AnnouncementType
  targetUsers?: TargetUserType
  isEnabled?: IntBoolean
  isTop?: IntBoolean
  status?: AnnouncementStatus
  sort?: SortOrder
  sortKey?: string
  lang?: string
};

export type AnnouncementIdOption = { announcementId: string | number };

export type GetAnnouncementsRequestOptions = RequestOptions<AnnouncementsQuery>;
export type GetAnnouncementRequestOptions = RequestOptions & AnnouncementIdOption;
export type CreateAnnouncementRequestOptions = RequestOptions<any, CreateAnnouncementData>;
export type UpdateAnnouncementRequestOptions = CreateAnnouncementRequestOptions & AnnouncementIdOption;
export type DeleteAnnouncementRequestOptions = AnnouncementIdOption;

export type AnnouncementResponse = Response<Announcement>;
export type AnnouncementsResponse = Response<Announcement[]>;

export type GetAnnouncementSpecifiedUsersRequestOptions = RequestOptions<BaseQuery> & AnnouncementIdOption;
export type AnnouncementSpecifiedUsersResponse = Response<AnnouncementSpecifiedUser[]>;

// Hot Keyword
export enum HotKeywordSortKey {
  RANK = 'rank',
  SEARCH_COUNT = 'searchCount',
  PLAY_COUNT = 'playCount',
  CREATED_AT = 'createdAt'
};

interface HotKeywordsQuery {
  sort?: SortOrder
  sortKey?: HotKeywordSortKey
};

export type HotKeywordIdOption = { hotKeywordId: string | number };
interface UpdateHotKeywordData {
  hotKeywordIds: number[]
};

export type GetHotKeywordsRequestOptions = RequestOptions<HotKeywordsQuery>;
export type CreateHotKeywordRequestOptions = RequestOptions<any, PartialHotKeyword>;
export type UpdateHotKeywordRankRequestOptions = RequestOptions<any, UpdateHotKeywordData>;
export type DeleteHotKeywordRequestOptions = HotKeywordIdOption;

export type HotKeywordsResponse = Response<HotKeyword[]>;

// RecommendKeyword
export enum RecommendKeywordSortKey {
  PRIORITY = 'priority',
  SEARCH_COUNT = 'searchCount',
  PLAY_COUNT = 'playCount',
  CREATED_AT = 'createdAt'
};

interface RecommendKeywordsQuery {
  sort?: SortOrder
  sortKey?: RecommendKeywordSortKey
};

export type RecommendKeywordIdOption = { keywordId: string | number };
interface UpdateRecommendKeywordData {
  keywordIds: number[]
};

export interface CreateRecommendKeywordData{
  keyword: MultiLangName
  workId: number
}

export type GetRecommendKeywordsRequestOptions = RequestOptions<RecommendKeywordsQuery>;
export type CreateRecommendKeywordRequestOptions = RequestOptions<any, CreateRecommendKeywordData>;
export type UpdateRecommendKeywordPriorityRequestOptions = RequestOptions<any, UpdateRecommendKeywordData>;
export type DeleteRecommendKeywordRequestOptions = RecommendKeywordIdOption;

export type RecommendKeywordsResponse = Response<RecommendKeyword[]>;

// Config
export enum ConfigKey {
  SEARCH_RECOMMEND_TOPIC ='search-recommend-topic',
  MOBILE_RECOMMEND_TOPIC = 'mobile-recommend-topic'
};

export type ConfigKeyOption = { key: ConfigKey };
export type ConfigValue = { value: string };

export type GetConfigRequestOptions = RequestOptions & ConfigKeyOption;
export type UpdateConfigRequestOptions = RequestOptions<any, ConfigValue> & ConfigKeyOption;

export enum KeywordStatisticsSortKey {
  DATE = 'date',
  ORGANIC_KEYWORD_SEARCH_COUNT = 'organicKeywordClickCount',
  ORGANIC_KEYWORD_PLAY_COUNT = 'organicKeywordPlayCount',
  HOT_KEYWORD_CLICK_COUNT = 'hotKeywordClickCount',
  RECOMMEND_KEYWORD_CLICK_COUNT = 'recommendKeywordClickCount',
  HOT_KEYWORD_PLAY_COUNT = 'hotKeywordPlayCount',
  RECOMMEND_KEYWORD_PLAY_COUNT = 'recommendKeywordPlayCount',
  HOT_KEYWORD_PLAY_RATE = 'hotKeywordPlayRate',
  RECOMMEND_KEYWORD_PLAY_RATE = 'recommendKeywordPlayRate'
};

export enum HotKeywordStatisticsSortKey {
  SEARCH_COUNT = 'searchCount',
  PLAY_COUNT = 'playCount',
  KEYWORD_CREATED_AT = 'keywordCreatedAt'
};

export enum RecommendedKeywordStatisticsSortKey {
  SEARCH_COUNT = 'searchCount',
  PLAY_COUNT = 'playCount',
  KEYWORD_CREATED_AT = 'keywordCreatedAt'
};

export enum OrganicKeywordStatisticsSortKey {
  SEARCH_COUNT = 'searchCount',
  PLAY_COUNT = 'playCount',
};

export enum WorkStatisticsSortKey {
  VIEWS = 'views',
  TRAILER_VIEWS = 'trailerViews',
  NO_EXPOSE_TRAILER_VIEWS = 'noExposeTrailerViews',
};

export interface KeywordStatisticsQuery extends BaseQuery {
  startDate?: string
  endDate?: string
  sort?: SortOrder
  sortKey?: string
};

export type HotKeywordStatisticsQuery = KeywordStatisticsQuery & {keyword?: string}
export type RecommendedKeywordStatisticsQuery = HotKeywordStatisticsQuery;
export type OrganicKeywordStatisticsQuery = HotKeywordStatisticsQuery;
export type WorkStatisticsQuery = HotKeywordStatisticsQuery & {agentId?: number, classificationId?: number, actorId?: number, genreId?: number, tagId?: number, status?: number};

export type GetKeywordStatisticsRequestOptions = RequestOptions<KeywordStatisticsQuery>;
export type GetHotKeywordStatisticsRequestOptions = RequestOptions<HotKeywordStatisticsQuery>;
export type GetRecommendedKeywordStatisticsRequestOptions = RequestOptions<RecommendedKeywordStatisticsQuery>;
export type GetOrganicKeywordStatisticsRequestOptions = RequestOptions<OrganicKeywordStatisticsQuery>;
export type GetWorkStatisticsRequestOptions = RequestOptions<WorkStatisticsQuery>;

export type KeywordStatisticsResponse = Response<KeywordStatistics[]>;
export type HotKeywordStatisticsResponse = Response<HotKeywordStatistics[]>;
export type RecommendedKeywordStatisticsResponse = Response<RecommendedKeywordStatistics[]>;
export type OrganicKeywordStatisticsResponse = Response<OrganicKeywordStatistics[]>;
export type WorkStatisticsResponse = Response<WorkStatistics[]>;

export interface KeywordStatisticsExcelQuery {
  startDate?: string
  endDate?: string
  sort?: SortOrder
  sortKey?: string
}

export type HotKeywordStatisticsExcelQuery = KeywordStatisticsExcelQuery & { keyword?: string };
export type RecommendedKeywordStatisticsExcelQuery = HotKeywordStatisticsExcelQuery;
export type OrganicKeywordStatisticsExcelQuery = HotKeywordStatisticsExcelQuery;
export type WorkStatisticsExcelQuery = HotKeywordStatisticsExcelQuery & {agentId?: number, classificationId?: number, actorId?: number, genreId?: number, tagId?: number, status?: number};

export type GetKeywordStatisticsExcelRequestOptions = RequestOptions<KeywordStatisticsExcelQuery>;
export type GetHotKeywordStatisticsExcelRequestOptions = RequestOptions<HotKeywordStatisticsExcelQuery>;
export type GetRecommendedKeywordStatisticsExcelRequestOptions = RequestOptions<RecommendedKeywordStatisticsExcelQuery>;
export type GetOrganicKeywordStatisticsExcelRequestOptions = RequestOptions<OrganicKeywordStatisticsExcelQuery>;
export type GetWorkStatisticsExcelRequestOptions = RequestOptions<WorkStatisticsExcelQuery>;
export type ExportExcelResponse = Response<BinaryType>;

// Channel Agent
interface ChannelAgentsQuery extends BaseQuery {
  keyword?: string
};

export type ChannelAgentIdOption = { channelAgentId: string };
export type GetChannelAgentRequestOptions = RequestOptions & ChannelAgentIdOption;
export type GetChannelAgentsRequestOptions = RequestOptions<ChannelAgentsQuery>;
export type CreateChannelAgentRequestOptions = RequestOptions<any, PartialChannelAgent>;
export type UpdateChannelAgentRequestOptions = CreateChannelAgentRequestOptions & ChannelAgentIdOption;
export type DeleteChannelAgentRequestOptions = ChannelAgentIdOption;

export type ChannelAgentResponse = Response<ChannelAgent>;
export type ChannelAgentsResponse = Response<ChannelAgent[]>;

// Channel Agent Withdrawal
export enum ChannelAgentWithdrawalSortKey {
  CREATED_AT = 'createdAt',
  STATUS = 'status'
};

interface ChannelAgentWithdrawalsQuery extends BaseQuery {
  keyword?: string
  startDate?: string
  endDate?: string
  sort?: SortOrder
  sortKey?: string
  status?: ChannelAgentWithdrawalStatus
};

export type ChannelAgentWithdrawalIdOption = { channelAgentWithdrawalId: string | number };

export type GetChannelAgentWithdrawalsRequestOptions = RequestOptions<ChannelAgentWithdrawalsQuery>;
export type ApproveChannelAgentWithdrawalRequestOptions = ChannelAgentWithdrawalIdOption;
export type TransferChannelAgentWithdrawalSuccessRequestOptions = ChannelAgentWithdrawalIdOption;
export type TransferChannelAgentWithdrawalFailedRequestOptions = ChannelAgentWithdrawalIdOption;

export type ExportChannelAgentWithdrawalsResponse = Response<BinaryType>;
export type ChannelAgentWithdrawalsResponse = Response<ChannelAgentWithdrawal[]>;

export interface ChannelAgentWithdrawalStatisticsQuery {
  startDate?: string
  endDate?: string
  keyword?: string
  status?: ChannelAgentWithdrawalStatus
}

export type GetChannelAgentWithdrawalStatisticsRequestOptions = RequestOptions<ChannelAgentWithdrawalStatisticsQuery>;

export type ChannelAgentWithdrawalStatisticsResponse = Response<ChannelAgentWithdrawalStatistics[]>;

// Campaign Commission
export type GetCampaignCommissionRequestOptions = RequestOptions & CampaignIdOption;
export type UpdateCampaignCommissionRequestOptions = RequestOptions<any, PartialCampaignCommission> & CampaignIdOption;
export type DeleteCampaignCommissionRequestOptions = CampaignIdOption;

export type CampaignCommissionResponse = Response<CampaignCommission>;
export type CampaignCommissionsResponse = Response<CampaignCommission[]>;

// Refund
export enum RefundTypeEnum {
  FULL = 'full',
  PARTIAL = 'partial'
}

export type RefundType = RefundTypeEnum.FULL | RefundTypeEnum.PARTIAL

export interface RefundOption {
  refundType: RefundType
  refundReason: string
}
export type UpdateRefundRequestOptions = RequestOptions<OrderIdOption, RefundOption>;

// Events
interface EventsQuery extends BaseQuery {
  keyword?: string
  startedAt?: string
  endedAt?: string
  status?: EventsStatusType
}

interface StatisticsQuery extends BaseQuery {
  startDate?: string
  endDate?: string
}

interface EnrollmentsQuery extends BaseQuery {
  keyword?: string
  startedAt?: string
  endedAt?: string
  verifiedStatus?: VerifiedStatus
  isCouponMember?: IntBoolean
  isNFTMember?: IntBoolean
  isBlack?: IntBoolean
  isContactMailVerified?: IntBoolean
}

// Events
export type GetEventsRequestOptions = RequestOptions<EventsQuery>;
export type EventsResponse = Response<Event[]>;
export type EventResponse = Response<Event>;
export type StatisticsResponse = Response<EventsStatistics[]>;
export type EnrollmentsResponse = Response<EventsEnrollments[]>;
export type IdOption = { id: string | number };
export type DeleteEventRequestOptions = IdOption;
export type GetEventsStatisticsRequestOptions = RequestOptions<StatisticsQuery> & IdOption;
export type GetEventsEnrollmentsRequestOptions = RequestOptions<EnrollmentsQuery> & IdOption;

export interface EventSection {
  id: string
  photoId: number
  photo?: Photo
  mobilePhotoId?: number
  mobilePhoto?: Photo
  name?: string
  linkType: TLinkType
  linkUrl: string
}

export interface CreateEventData {
  slug: string
  name: string
  startedAt: string
  endedAt?: string
  enrollEndedAt?: string
  seoTitle?: string
  seoDescription?: string
  seoKeywords?: string
  emailNotification?: string
  eventSections: EventSection[]
}

export type CreateEventRequestOptions = RequestOptions<any, CreateEventData>;
export type UpdateEventRequestOptions = RequestOptions<any, CreateEventData> & IdOption;

export type ExportEventsStatistics = RequestOptions<{
  startDate?: string
  endDate?: string
}>
export type ExportEventsStatisticsResponse = Response<BinaryType>;

export type ExportResponse = Response<BinaryType>;
