/*
 * @Description: user mutations type
 * @Author: ZY
 * @Date: 2020-12-23 10:25:37
 * @LastEditors: ZY
 * @LastEditTime: 2020-12-28 11:33:53
 */

export enum UserMutationTypes {
  SET_ID = 'SET_ID',
  SET_USERNAME = 'SET_USERNAME',
  SET_NAME = 'SET_NAME',
  SET_PHONE = 'SET_PHONE',
  SET_STATUS = 'SET_STATUS',
  SET_ROLES = 'SET_ROLES',
  SET_TOKEN = 'SET_TOKEN',
  SET_CREATED_AT = 'SET_CREATED_AT',
}
