import { request } from './utils';

import {
  RoleResponse,
  RolesResponse,
  GetRoleRequestOptions,
  GetRolesRequestOptions,
  CreateRoleRequestOptions,
  UpdateRoleRequestOptions,
  DeleteRoleRequestOptions,
  SucceededResponse
} from './interfaces';

export function getRole({ roleId }: GetRoleRequestOptions): Promise<RoleResponse> {
  return request({
    url: `/roles/${roleId}`,
    method: 'get'
  });
}

export function getRoles({ query }: GetRolesRequestOptions): Promise<RolesResponse> {
  return request({
    url: '/roles',
    method: 'get',
    params: query
  });
}

export function createRole({ data }: CreateRoleRequestOptions): Promise<SucceededResponse> {
  return request({
    url: '/roles',
    method: 'post',
    data
  });
}

export function updateRole({ roleId, data }: UpdateRoleRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/roles/${roleId}`,
    method: 'put',
    data
  });
}

export function deleteRole({ roleId }: DeleteRoleRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/roles/${roleId}`,
    method: 'delete'
  });
}
