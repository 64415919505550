import { shallowRef } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const searchRouter: Array<RouteRecordRaw> = [
  {
    path: '/search',
    component: shallowRef(Layout),
    redirect: '/search/hot-keywords',
    meta: {
      title: 'search',
      icon: '#icon-search'
    },
    children: [
      {
        path: 'hot-keywords',
        component: () => import(/* webpackChunkName: "hot-keywords-daily" */ '@/views/hot-keywords/List.vue'),
        name: 'list_hot-keywords',
        meta: {
          title: 'hot-keywords',
          icon: '#icon-topic'
        }
      },
      {
        path: 'hot-keywords/create',
        component: () => import(/* webpackChunkName: "hot-keywords-create" */ '@/views/hot-keywords/Create.vue'),
        name: 'create_hot-keyword',
        meta: {
          title: 'createHotKeyword',
          activeMenu: '/search/hot-keywords',
          hidden: true
        }
      },
      {
        path: 'recommend-keywords',
        component: () => import(/* webpackChunkName: "keywords-daily" */ '@/views/recommend-keywords/List.vue'),
        name: 'list_recommend-keywords',
        meta: {
          title: 'keywords',
          activeMenu: '/search/recommend-keywords',
          icon: '#icon-work'
        }
      },
      {
        path: 'recommend-keywords/create',
        component: () => import(/* webpackChunkName: "keywords-create" */ '@/views/recommend-keywords/Create.vue'),
        name: 'create_recommend-keyword',
        meta: {
          title: 'createKeyword',
          activeMenu: '/search/keywords',
          hidden: true
        }
      },
      {
        path: 'recommended-topic/edit',
        component: () => import(/* webpackChunkName: "hot-keywords-edit" */ '@/views/recommended-topic/Edit.vue'),
        name: 'update_config',
        meta: {
          title: 'recommended-topic',
          noCache: true,
          activeMenu: '/search/recommended-topic/edit',
          icon: '#icon-recommend'
        }
      }
      // TODO:
      // {
      //   path: 'directors',
      //   component: () => import(/* webpackChunkName: "directors-daily" */ '@/views/directors/List.vue'),
      //   name: 'list_directors',
      //   meta: {
      //     title: 'directors',
      //     activeMenu: '/search/directors',
      //     icon: '#icon-director'
      //   }
      // },
      // {
      //   path: 'directors/create',
      //   component: () => import(/* webpackChunkName: "directors-daily" */ '@/views/directors/Create.vue'),
      //   name: 'create_director',
      //   meta: {
      //     title: 'createDirector',
      //     activeMenu: '/search/directors',
      //     hidden: true
      //   }
      // },
      // {
      //   path: 'directors/edit/:id(\\d+)',
      //   component: () => import(/* webpackChunkName: "directors-daily" */ '@/views/directors/Edit.vue'),
      //   name: 'edit_director',
      //   meta: {
      //     title: 'editDirector',
      //     activeMenu: '/search/directors',
      //     hidden: true
      //   }
      // },
      // {
      //   path: 'actors',
      //   component: () => import(/* webpackChunkName: "actors-daily" */ '@/views/actors/List.vue'),
      //   name: 'list_actors',
      //   meta: {
      //     title: 'actors',
      //     activeMenu: '/search/actors',
      //     icon: '#icon-model'
      //   }
      // },
      // {
      //   path: 'actors/create',
      //   component: () => import(/* webpackChunkName: "actors-daily" */ '@/views/actors/Create.vue'),
      //   name: 'create_actor',
      //   meta: {
      //     title: 'createActor',
      //     activeMenu: '/search/actors',
      //     hidden: true
      //   }
      // },
      // {
      //   path: 'actors/edit/:id(\\d+)',
      //   component: () => import(/* webpackChunkName: "actors-daily" */ '@/views/actors/Edit.vue'),
      //   name: 'edit_actor',
      //   meta: {
      //     title: 'editActor',
      //     activeMenu: '/search/actors',
      //     hidden: true
      //   }
      // },
      // {
      //   path: 'genres',
      //   component: () => import(/* webpackChunkName: "genres-daily" */ '@/views/genres/List.vue'),
      //   name: 'list_genres',
      //   meta: {
      //     title: 'genres',
      //     activeMenu: '/search/genres',
      //     icon: '#icon-genre'
      //   }
      // },
      // {
      //   path: 'genres/create',
      //   component: () => import(/* webpackChunkName: "genres-daily" */ '@/views/genres/Create.vue'),
      //   name: 'create_genre',
      //   meta: {
      //     title: 'createGenre',
      //     activeMenu: '/search/genres',
      //     hidden: true
      //   }
      // },
      // {
      //   path: 'genres/edit/:id(\\d+)',
      //   component: () => import(/* webpackChunkName: "genres-daily" */ '@/views/genres/Edit.vue'),
      //   name: 'edit_genre',
      //   meta: {
      //     title: 'editGenre',
      //     activeMenu: '/search/genres',
      //     hidden: true
      //   }
      // }
    ]
  }
];

export default searchRouter;
