import { request } from './utils';

import {
  ExportExcelResponse,
  GetHotKeywordStatisticsExcelRequestOptions,
  GetHotKeywordStatisticsRequestOptions,
  GetKeywordStatisticsExcelRequestOptions,
  GetKeywordStatisticsRequestOptions,
  GetOrganicKeywordStatisticsExcelRequestOptions,
  GetOrganicKeywordStatisticsRequestOptions,
  GetRecommendedKeywordStatisticsExcelRequestOptions,
  GetRecommendedKeywordStatisticsRequestOptions,
  GetWorkStatisticsExcelRequestOptions,
  GetWorkStatisticsRequestOptions,
  HotKeywordStatisticsResponse,
  KeywordStatisticsResponse,
  OrganicKeywordStatisticsResponse,
  RecommendedKeywordStatisticsResponse,
  WorkStatisticsResponse
} from './interfaces';

export function getHotKeywordStatistics({ query }: GetHotKeywordStatisticsRequestOptions = {}): Promise<HotKeywordStatisticsResponse> {
  return request({
    url: '/search-statistics/hot-keywords',
    method: 'get',
    params: query
  });
}

export function getRecommendedKeywordStatistics({ query }: GetRecommendedKeywordStatisticsRequestOptions = {}): Promise<RecommendedKeywordStatisticsResponse> {
  return request({
    url: '/search-statistics/recommend-keywords',
    method: 'get',
    params: query
  });
}

export function getOrganicKeywordStatistics({ query }: GetOrganicKeywordStatisticsRequestOptions = {}): Promise<OrganicKeywordStatisticsResponse> {
  return request({
    url: '/search-statistics/organic-keywords',
    method: 'get',
    params: query
  });
}

export function getKeywordStatistics({ query }: GetKeywordStatisticsRequestOptions = {}): Promise<KeywordStatisticsResponse> {
  return request({
    url: '/search-statistics/keywords',
    method: 'get',
    params: query
  });
}

export function getWorkStatistics({ query }: GetWorkStatisticsRequestOptions = {}): Promise<WorkStatisticsResponse> {
  return request({
    url: '/work-statistics',
    method: 'get',
    params: query
  });
}

export function exportKeywordStatisticsExcel({ query }: GetKeywordStatisticsExcelRequestOptions): Promise<ExportExcelResponse> {
  return request({
    url: '/search-statistics/keywords/export',
    method: 'get',
    params: query,
    responseType: 'blob'
  });
}

export function exportHotKeywordStatisticsExcel({ query }: GetHotKeywordStatisticsExcelRequestOptions): Promise<ExportExcelResponse> {
  return request({
    url: '/search-statistics/hot-keywords/export',
    method: 'get',
    params: query,
    responseType: 'blob'
  });
}

export function exportRecommendedKeywordStatisticsExcel({ query }: GetRecommendedKeywordStatisticsExcelRequestOptions): Promise<ExportExcelResponse> {
  return request({
    url: '/search-statistics/recommend-keywords/export',
    method: 'get',
    params: query,
    responseType: 'blob'
  });
}

export function exportOrganicKeywordStatisticsExcel({ query }: GetOrganicKeywordStatisticsExcelRequestOptions): Promise<ExportExcelResponse> {
  return request({
    url: '/search-statistics/organic-keywords/export',
    method: 'get',
    params: query,
    responseType: 'blob'
  });
}

export function exportWorkStatisticsExcel({ query }: GetWorkStatisticsExcelRequestOptions): Promise<ExportExcelResponse> {
  return request({
    url: '/work-statistics/export',
    method: 'get',
    params: query,
    responseType: 'blob'
  });
}
