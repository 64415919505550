import { request } from './utils';

import {
  TopicResponse,
  TopicsResponse,
  CreateTopicRequestOptions,
  DeleteTopicRequestOptions,
  SucceededResponse,
  GetTopicRequestOptions,
  GetTopicsRequestOptions,
  UpdateTopicRequestOptions,
  CreateTopicWorkRequestOptions,
  DeleteTopicWorkRequestOptions,
  TopicIdOption,
  GetTopicWorksRequestOptions,
  WorksResponse
} from './interfaces';

export function getTopic({ topicId }: GetTopicRequestOptions): Promise<TopicResponse> {
  return request({
    url: `/topics/${topicId}`,
    method: 'get'
  });
}

export function getTopics({ query }: GetTopicsRequestOptions): Promise<TopicsResponse> {
  return request({
    url: '/topics',
    method: 'get',
    params: query
  });
}

export function createTopic({ data }: CreateTopicRequestOptions): Promise<TopicResponse> {
  return request({
    url: '/topics',
    method: 'post',
    data
  });
}

export function updateTopic({ topicId, data }: UpdateTopicRequestOptions): Promise<TopicResponse> {
  return request({
    url: `/topics/${topicId}`,
    method: 'put',
    data
  });
}

export function toggleTopic({ topicId }: TopicIdOption): Promise<SucceededResponse> {
  return request({
    url: `/topics/${topicId}/toggle`,
    method: 'post'
  });
}

export function deleteTopic({ topicId }: DeleteTopicRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/topics/${topicId}`,
    method: 'delete'
  });
}

export function createTopicWork({ topicId, workId }: CreateTopicWorkRequestOptions): Promise<TopicResponse> {
  return request({
    url: `/topics/${topicId}/works/${workId}`,
    method: 'put'
  });
}

export function deleteTopicWork({ topicId, workId }: DeleteTopicWorkRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/topics/${topicId}/works/${workId}`,
    method: 'delete'
  });
}

export function getTopicWorks({ query }: GetTopicWorksRequestOptions): Promise<WorksResponse> {
  return request({
    url: '/topics/works',
    method: 'get',
    params: query
  });
}
