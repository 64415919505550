import { createFormData, request } from './utils';
import { FORM_DATE_HEADER } from './constants';

import {
  AdvertisementResponse,
  AdvertisementsResponse,
  CreateAdvertisementRequestOptions,
  DeleteAdvertisementRequestOptions,
  SucceededResponse,
  GetAdvertisementRequestOptions,
  GetAdvertisementsRequestOptions,
  UpdateAdvertisementRequestOptions,
  BaseQuery,
  RequestOptions,
  GetAdvertisementsStatisticsRequestOptions,
  AdvertisementsStatisticsResponse
} from './interfaces';

export function getAdvertisement({ id }: GetAdvertisementRequestOptions): Promise<AdvertisementResponse> {
  return request({
    url: `/advertisements/${id}`,
    method: 'get'
  });
}

export function getAdvertisements({ query }: GetAdvertisementsRequestOptions): Promise<AdvertisementsResponse> {
  return request({
    url: '/advertisements',
    method: 'get',
    params: query
  });
}

export function createAdvertisement({ data }: CreateAdvertisementRequestOptions): Promise<AdvertisementResponse> {
  return request({
    url: '/advertisements',
    method: 'post',
    data
  });
}

export function updateAdvertisement({ id, data }: UpdateAdvertisementRequestOptions): Promise<AdvertisementResponse> {
  return request({
    url: `/advertisements/${id}`,
    method: 'put',
    data
  });
}

export function deleteAdvertisement({ id }: DeleteAdvertisementRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/advertisements/${id}`,
    method: 'delete'
  });
}

export function getAdvertisementsStatistics({ query }: GetAdvertisementsStatisticsRequestOptions): Promise<AdvertisementsStatisticsResponse> {
  return request({
    url: '/analytics/advertisements',
    method: 'get',
    params: query
  });
}
