import { shallowRef } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const orderRouter: Array<RouteRecordRaw> = [
  {
    path: '/orders',
    component: shallowRef(Layout),
    // redirect: 'noredirect',
    meta: {
      title: 'orders',
      icon: '#icon-order'
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "order-list" */ '@/views/orders/List.vue'),
        name: 'list_orders',
        meta: {
          title: 'orders',
          icon: '#icon-order'
        }
      },
      {
        path: 'show/:id(\\d+)',
        component: () => import(/* webpackChunkName: "order-show" */ '@/views/orders/Show.vue'),
        name: 'show_order',
        meta: {
          title: 'showOrder',
          noCache: true,
          activeMenu: '/orders',
          hidden: true
        }
      }
    ]
  }
];

export default orderRouter;
