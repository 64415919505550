import { request } from './utils';

import {
  CampaignResponse,
  CampaignsResponse,
  GetCampaignRequestOptions,
  GetCampaignsRequestOptions,
  CreateCampaignRequestOptions,
  SucceededResponse,
  BaseQuery,
  RequestOptions,
  CampaignsDailyResponse,
  CalibrateCampaignRequestOptions,
  UpdateCampaignRequestOptions,
  DeleteCampaignRequestOptions,
  GetCampaignCommissionRequestOptions,
  CampaignCommissionResponse,
  UpdateCampaignCommissionRequestOptions,
  CreateCpaRuleRequestOptions,
  CpaOverviewResponse,
  GetCpaStatisticsOptions,
  UpdateCpaStatisticsOptions,
  ExportCpaOptions,
  ExportExcelResponse,
  GetCpaStatisticsResponse
} from './interfaces';

export function getCampaign({ campaignId }: GetCampaignRequestOptions): Promise<CampaignResponse> {
  return request({
    url: `/campaigns/${campaignId}`,
    method: 'get'
  });
}

export function getCampaigns({ query }: GetCampaignsRequestOptions = {}): Promise<CampaignsResponse> {
  return request({
    url: '/campaigns',
    method: 'get',
    params: query
  });
}

export function createCampaign({ data }: CreateCampaignRequestOptions): Promise<SucceededResponse> {
  return request({
    url: '/campaigns',
    method: 'post',
    data
  });
}

export function updateCampaign({ campaignId, data }: UpdateCampaignRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/campaigns/${campaignId}`,
    method: 'put',
    data
  });
}

export function deleteCampaign({ campaignId }: DeleteCampaignRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/campaigns/${campaignId}`,
    method: 'delete'
  });
}

export function getCampaignCommission({ campaignId }: GetCampaignCommissionRequestOptions): Promise<CampaignCommissionResponse> {
  return request({
    url: `/campaigns/${campaignId}/commission`,
    method: 'get'
  });
}

export function updateCampaignCommission({ campaignId, data }: UpdateCampaignCommissionRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/campaigns/${campaignId}/commission`,
    method: 'put',
    data
  });
}

/** 取得特地每日渠道合作金額列表 */
export function getCampaignsDaily(
  { campaignId }: { campaignId: number },
  { query }: RequestOptions<BaseQuery> = {}): Promise<CampaignsDailyResponse> {
  return request({
    url: `/campaigns/${campaignId}/daily`,
    method: 'get',
    params: query
  });
}

/** 校正合作模式 */
export function calibrateCampaignDaily(
  { campaignId }: { campaignId: number },
  { data }: CalibrateCampaignRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/campaigns/${campaignId}/daily`,
    method: 'put',
    data
  });
}

/** 匯出渠道合作活動列表 */
export function exportCampaignsDaily(): Promise<ExportExcelResponse> {
  return request({
    url: '/campaigns/export',
    method: 'get',
    responseType: 'blob'
  });
}

/** 取渠道合作 cpa 規則 */
export function getCpaRule(id: number): Promise<SucceededResponse> {
  return request({
    url: `/campaigns/${id}/cpa`,
    method: 'get'
  });
}

/** 設定渠道合作 cpa 規則 */
export function createCpaRule(
  { id }: { id: number },
  { data }: CreateCpaRuleRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/campaigns/${id}/cpa`,
    method: 'post',
    data
  });
}

/** 取渠道合作 cpa 數據總覽 */
export function getCpaOverview(id: number): Promise<CpaOverviewResponse> {
  return request({
    url: `/campaigns/${id}/cpa-overview`,
    method: 'get'
  });
}

/** 取渠道合作 cpa 數據 */
export function getCpaStatistics(
  { id }: { id: number },
  { query }: GetCpaStatisticsOptions): Promise<GetCpaStatisticsResponse> {
  return request({
    url: `/campaigns/${id}/cpa-statistics`,
    method: 'get',
    params: query
  });
}

/** 編輯渠道合作 cpa 數據 */
export function updateCpaStatistics(
  { id }: { id: number },
  { data }: UpdateCpaStatisticsOptions): Promise<SucceededResponse> {
  return request({
    url: `/campaigns/${id}/cpa-statistics`,
    method: 'put',
    data
  });
}

/** 匯出 cpa 渠道合作詳情列表 */
export function exportCpa(
  { id }: { id: number },
  { query }: ExportCpaOptions): Promise<ExportExcelResponse> {
  return request({
    url: `/campaigns/${id}/cpa-export`,
    method: 'get',
    responseType: 'blob'
  });
}
