import { shallowRef } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const channelRouter: Array<RouteRecordRaw> = [
  {
    path: '/search-statistics',
    component: shallowRef(Layout),
    redirect: '/search-statistics/overview',
    meta: {
      title: 'search-statistics',
      icon: '#icon-analysis'
    },
    children: [
      {
        path: 'keywords',
        component: () => import('@/views/search-statistics/KeywordsList.vue'),
        name: 'list_search-statistics',
        meta: {
          title: 'keywords-statistics',
          icon: '#icon-analysis'
        }
      },
      {
        path: 'popular-keywords',
        component: () => import('@/views/search-statistics/HotKeywordList.vue'),
        name: 'list_hot-search-statistics',
        meta: {
          title: 'popular-keyword-statistics',
          icon: '#icon-analysis'
        }
      },
      {
        path: 'recommended-keywords',
        component: () => import('@/views/search-statistics/RecommendedKeywordList.vue'),
        name: 'list_recommend-search-statistics',
        meta: {
          title: 'recommended-keyword-statistics',
          icon: '#icon-analysis'
        }
      },
      {
        path: 'organic-keywords',
        component: () => import('@/views/search-statistics/OrganicKeywordList.vue'),
        name: 'list_organic-search-statistics',
        meta: {
          title: 'organic-keyword-statistics',
          icon: '#icon-analysis'
        }
      },
      {
        path: 'work-keywords',
        component: () => import('@/views/work-statistics/List.vue'),
        name: 'list_work-statistics',
        meta: {
          title: 'work-statistics',
          icon: '#icon-analysis'
        }
      }
    ]
  }
];

export default channelRouter;
