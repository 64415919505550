import { request } from './utils';

import {
  SubscriptionsResponse,
  GetSubscriptionsRequestOptions,
  SucceededResponse,
  SubscriptionIdOption,
  GetSubscriptionCancellationsRequestOptions,
  SubscriptionCancellationsResponse,
  ExportResponse,
  SubscriptionCancellationOptions
} from './interfaces';

export function getSubscriptions({ query }: GetSubscriptionsRequestOptions): Promise<SubscriptionsResponse> {
  return request({
    url: '/subscriptions',
    method: 'get',
    params: query
  });
}

export function updateSubscription({ planId }: SubscriptionIdOption): Promise<SucceededResponse> {
  return request({
    url: `/subscriptions/cancel/${planId}`,
    method: 'put'
  });
}

export function getSubscriptionCancellations(
  { query }: GetSubscriptionCancellationsRequestOptions):
  Promise<SubscriptionCancellationsResponse> {
  return request({
    url: '/subscriptions/cancellations',
    method: 'get',
    params: query
  });
}

export function exportSubscriptionCancellations(
  { query }: GetSubscriptionCancellationsRequestOptions): Promise<ExportResponse> {
  return request({
    url: '/subscriptions/cancellations/export',
    method: 'get',
    params: query,
    responseType: 'blob'
  });
}

export function subscriptionCancellation({ id, data }: SubscriptionCancellationOptions): Promise<SucceededResponse> {
  return request({
    url: `/subscriptions/cancel/${id}`,
    method: 'put',
    data
  });
}
