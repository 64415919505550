import { request } from './utils';

import {
  // AgentResponse,
  AgentsResponse,
  // GetAgentRequestOptions,
  CreateAgentRequestOptions,
  GetAgentsRequestOptions,
  IdOption,
  SucceededResponse,
  UpdateAgentRequestOptions
} from './interfaces';

// export function getAgent({ agentId }: GetAgentRequestOptions): Promise<AgentResponse> {
//   return request({
//     url: `/agents/${agentId}`,
//     method: 'get'
//   });
// }

export function getAgents({ query }: GetAgentsRequestOptions = {}): Promise<AgentsResponse> {
  return request({
    url: '/agents',
    method: 'get',
    params: query
  });
}

export function createAgent({ data }: CreateAgentRequestOptions): Promise<SucceededResponse> {
  return request({
    url: '/agents',
    method: 'post',
    data
  });
}

export function updateAgent({ query, data }: UpdateAgentRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/agents/${query.id}`,
    method: 'put',
    data
  });
}

export function deleteAgent({ id }: IdOption): Promise<SucceededResponse> {
  return request({
    url: `/agents/${id}`,
    method: 'delete'
  });
}
