
import { defineComponent } from 'vue';
import settings from '@/config/default/setting.config';

export default defineComponent({
  props: {
    collapse: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const title = settings.title;
    const Logo = 'https://www.modeltv.com/_nuxt/img/logo-yellow.39823f1.svg';

    return {
      title,
      Logo
    };
  }
});
