import { request } from './utils';

import {
  ChannelAgentResponse,
  ChannelAgentsResponse,
  GetChannelAgentRequestOptions,
  GetChannelAgentsRequestOptions,
  UpdateChannelAgentRequestOptions,
  CreateChannelAgentRequestOptions,
  DeleteChannelAgentRequestOptions,
  SucceededResponse
} from './interfaces';

export function getChannelAgent({ channelAgentId }: GetChannelAgentRequestOptions): Promise<ChannelAgentResponse> {
  return request({
    url: `/channel-agents/${channelAgentId}`,
    method: 'get'
  });
}

export function getChannelAgents({ query }: GetChannelAgentsRequestOptions): Promise<ChannelAgentsResponse> {
  return request({
    url: '/channel-agents',
    method: 'get',
    params: query
  });
}

export function createChannelAgent({ data }: CreateChannelAgentRequestOptions): Promise<ChannelAgentResponse> {
  return request({
    url: '/channel-agents',
    method: 'post',
    data
  });
}

export function updateChannelAgent({ channelAgentId, data }: UpdateChannelAgentRequestOptions): Promise<ChannelAgentResponse> {
  return request({
    url: `/channel-agents/${channelAgentId}`,
    method: 'put',
    data
  });
}

export function deleteChannelAgent({ channelAgentId }: DeleteChannelAgentRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/channel-agents/${channelAgentId}`,
    method: 'delete'
  });
}
