import { shallowRef } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const eventPageRouter: Array<RouteRecordRaw> = [
  {
    path: '/events',
    component: shallowRef(Layout),
    meta: {
      title: 'events',
      icon: '#icon-event'
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "event-list" */ '@/views/events/List.vue'),
        name: 'list_events',
        meta: {
          title: 'eventList',
          icon: '#icon-tablefile'
        }
      },
      {
        path: 'banner',
        component: () => import(/* webpackChunkName: "advertisements-list" */ '@/views/advertisements/List.vue'),
        name: 'list_advertisements',
        meta: {
          title: 'advertisementsList',
          icon: '#icon-banner'
        }
      },
      {
        path: 'banner/create',
        component: () => import(/* webpackChunkName: "advertisements-create" */ '@/views/advertisements/Create.vue'),
        name: 'create_advertisement',
        meta: {
          title: 'createAdvertisement',
          activeMenu: '/events/advertisements',
          hidden: true
        }
      },
      {
        path: 'banner/edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "advertisements-edit" */ '@/views/advertisements/Edit.vue'),
        name: 'edit_advertisement',
        meta: {
          title: 'editAdvertisement',
          noCache: true,
          activeMenu: '/events/advertisements',
          hidden: true
        }
      },
      {
        path: 'statistics',
        component: () => import(/* webpackChunkName: "statistics-list" */ '@/views/advertisements-statistics/List.vue'),
        name: 'list_analytics',
        meta: {
          title: 'listAnalytics',
          icon: '#icon-chart',
          activeMenu: '/events/statistics'
        }
      },
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "event-create" */ '@/views/events/Create.vue'),
        name: 'create_event',
        meta: {
          title: 'createEvent',
          activeMenu: '/events',
          hidden: true
        }
      },
      {
        path: 'edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "event-edit" */ '@/views/events/Edit.vue'),
        name: 'edit_event',
        meta: {
          title: 'editEvent',
          noCache: true,
          activeMenu: '/events',
          hidden: true
        }
      },
      {
        path: 'position/create',
        component: () => import(/* webpackChunkName: "position-create" */ '@/views/events/position/Create.vue'),
        name: 'create_position',
        meta: {
          title: 'createPosition',
          activeMenu: '/events',
          hidden: true
        }
      },
      {
        path: 'position/edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "position-edit" */ '@/views/events/position/Edit.vue'),
        name: 'edit_position',
        meta: {
          title: 'editPosition',
          noCache: true,
          activeMenu: '/events',
          hidden: true
        }
      },
      {
        path: ':id(\\d+)/enrollments',
        component: () => import(/* webpackChunkName: "enrollments-list" */ '@/views/events/enrollments/List.vue'),
        name: 'list_event_enrollments',
        meta: {
          title: 'enrollmentsList',
          activeMenu: '/events',
          icon: '#icon-chart',
          hidden: true
        }
      },
      {
        path: ':id(\\d+)/statistics',
        component: () => import(/* webpackChunkName: "statistics-list" */ '@/views/events/statistics/List.vue'),
        name: 'list_event_daily_reports',
        meta: {
          title: 'statisticsList',
          activeMenu: '/events',
          icon: '#icon-chart',
          hidden: true
        }
      }
    ]
  },
  {
    path: '/events/:id(\\d+)/preview',
    component: () => import(/* webpackChunkName: "event-preview" */ '@/views/events/Preview.vue'),
    name: 'preview_event',
    meta: {
      title: 'previewEvent',
      hidden: true
    }
  }
];

export default eventPageRouter;
