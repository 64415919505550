import { request } from './utils';

import {
  ChannelResponse,
  ChannelsResponse,
  GetChannelRequestOptions,
  GetChannelsRequestOptions,
  CreateChannelRequestOptions,
  UpdateChannelRequestOptions,
  DeleteChannelRequestOptions,
  SucceededResponse,
  GetChannelsAgentsRequestOptions,
  ChannelAgentResponse,
  GetChannelAgentWithdrawalsRequestOptions,
  ChannelAgentWithdrawalsResponse,
  ChannelAgentWithdrawalIdOption,
  ExportChannelAgentWithdrawalsResponse
} from './interfaces';

export function getChannel({ channelId }: GetChannelRequestOptions): Promise<ChannelResponse> {
  return request({
    url: `/channels/${channelId}`,
    method: 'get'
  });
}

export function getChannels({ query }: GetChannelsRequestOptions = {}): Promise<ChannelsResponse> {
  return request({
    url: '/channels',
    method: 'get',
    params: query
  });
}

export function createChannel({ data }: CreateChannelRequestOptions): Promise<ChannelResponse> {
  return request({
    url: '/channels',
    method: 'post',
    data
  });
}

export function updateChannel({ channelId, data }: UpdateChannelRequestOptions): Promise<ChannelResponse> {
  return request({
    url: `/channels/${channelId}`,
    method: 'put',
    data
  });
}

export function deleteChannel({ channelId }: DeleteChannelRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/channels/${channelId}`,
    method: 'delete'
  });
}

export function getChannelAgentWithdrawals({ query }: GetChannelAgentWithdrawalsRequestOptions = {}): Promise<ChannelAgentWithdrawalsResponse> {
  return request({
    url: '/channel-agents/withdrawals',
    method: 'get',
    params: query
  });
}

export function exportChannelAgentWithdrawals({ query }: GetChannelAgentWithdrawalsRequestOptions): Promise<ExportChannelAgentWithdrawalsResponse> {
  return request({
    url: '/channel-agents/withdrawals/export',
    method: 'get',
    params: query,
    responseType: 'blob'
  });
}

export function approveChannelAgentWithdrawal({
  channelAgentWithdrawalId
}: ChannelAgentWithdrawalIdOption): Promise<SucceededResponse> {
  return request({
    url: `/channel-agents/withdrawals/${channelAgentWithdrawalId}/approve`,
    method: 'put'
  });
}

export function transferChannelAgentWithdrawalSuccess({
  channelAgentWithdrawalId
}: ChannelAgentWithdrawalIdOption): Promise<SucceededResponse> {
  return request({
    url: `/channel-agents/withdrawals/${channelAgentWithdrawalId}/transfer-success`,
    method: 'put'
  });
}

export function transferChannelAgentWithdrawalFailed({
  channelAgentWithdrawalId
}: ChannelAgentWithdrawalIdOption): Promise<SucceededResponse> {
  return request({
    url: `/channel-agents/withdrawals/${channelAgentWithdrawalId}/transfer-failed`,
    method: 'put'
  });
}
