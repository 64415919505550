import { request } from './utils';

import {
  HotKeywordsResponse,
  GetHotKeywordsRequestOptions,
  CreateHotKeywordRequestOptions,
  UpdateHotKeywordRankRequestOptions,
  DeleteHotKeywordRequestOptions,
  SucceededResponse
} from './interfaces';

export function getHotKeywords({ query }: GetHotKeywordsRequestOptions = {}): Promise<HotKeywordsResponse> {
  return request({
    url: '/hot-keywords',
    method: 'get',
    params: query
  });
}

export function createHotKeyword({ data }: CreateHotKeywordRequestOptions): Promise<SucceededResponse> {
  return request({
    url: '/hot-keywords',
    method: 'post',
    data
  });
}

export function updateHotKeywordRank({ data }: UpdateHotKeywordRankRequestOptions): Promise<SucceededResponse> {
  return request({
    url: '/hot-keywords/rank',
    method: 'put',
    data
  });
}

export function deleteHotKeyword({ hotKeywordId }: DeleteHotKeywordRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/hot-keywords/${hotKeywordId}`,
    method: 'delete'
  });
}
