import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_AppMain = _resolveComponent("AppMain")!
  const _component_Settings = _resolveComponent("Settings")!
  const _component_RightPanel = _resolveComponent("RightPanel")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "fade-transform",
    mode: "out-in"
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_KeepAlive, {
        include: _ctx.cachedViews()
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.classObj, "app-wrapper"])
        }, [
          (_ctx.classObj.mobile && _ctx.sidebar.opened)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "drawer-bg",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClickOutside && _ctx.handleClickOutside(...args)))
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_Sidebar, { class: "sidebar-container" }),
          _createElementVNode("div", {
            class: _normalizeClass([{hasTagsView: _ctx.showTagsView}, "main-container"])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass({'fixed-header': _ctx.fixedHeader})
            }, [
              _createVNode(_component_Navbar)
            ], 2),
            _createVNode(_component_AppMain),
            (_ctx.showSettings)
              ? (_openBlock(), _createBlock(_component_RightPanel, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Settings)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ], 2)
        ], 2)
      ], 1032, ["include"]))
    ]),
    _: 1
  }))
}