import { request } from './utils';

import {
  SucceededResponse,
  BlockResponse,
  BlocksResponse,
  GetBlocksRequestOptions,
  BlockTopicOptions,
  BlockFeatureWorkOptions
} from './interfaces';

export function getBlocks({ query }: GetBlocksRequestOptions = {}): Promise<BlocksResponse> {
  return request({
    url: '/blocks',
    method: 'get',
    params: query
  });
}

export function getBlock(id: number): Promise<BlockResponse> {
  return request({
    url: `/blocks/${id}`,
    method: 'get'
  });
}

export function createBlockTopics({ blockId, data }: BlockTopicOptions): Promise<SucceededResponse> {
  return request({
    url: `/blocks/${blockId}/topics`,
    method: 'post',
    data
  });
}

export function sortBlockTopics({ blockId, data }: BlockTopicOptions): Promise<SucceededResponse> {
  return request({
    url: `/blocks/${blockId}/topics`,
    method: 'put',
    data
  });
}

export function deleteBlockTopics({ blockId, data }: BlockTopicOptions): Promise<SucceededResponse> {
  return request({
    url: `/blocks/${blockId}/topics`,
    method: 'delete',
    data
  });
}

export function createBlockFeatureWorks({ blockId, data }: BlockFeatureWorkOptions): Promise<SucceededResponse> {
  return request({
    url: `/blocks/${blockId}/feature-works`,
    method: 'post',
    data
  });
}

export function sortBlockFeatureWorks({ blockId, data }: BlockFeatureWorkOptions): Promise<SucceededResponse> {
  return request({
    url: `/blocks/${blockId}/feature-works`,
    method: 'put',
    data
  });
}

export function deleteBlockFeatureWorks({ blockId, data }: BlockFeatureWorkOptions): Promise<SucceededResponse> {
  return request({
    url: `/blocks/${blockId}/feature-works`,
    method: 'delete',
    data
  });
}
